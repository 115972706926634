import { TextField, Tooltip } from '@cimpress/react-components'
import { useField } from 'formik'
import { TFunction } from 'i18next'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import AnchorButton from '../common/components/AnchorButton'
import HighlightedSelect from '../common/components/HighlightedSelect'
import Preloader from '../common/components/Preloader'
import { ValidatedSelect } from '../common/components/ValidatedSelect'
import ValidationErrorMessage from '../common/components/ValidationErrorMessage'
import { clone } from '../common/helpers/clone'
import { anyWholeWordStartsWithIgnoringCase } from '../common/helpers/filters'
import { ValidationStatus } from '../common/helpers/validation'
import { FulfillmentLocation, Option } from '../common/models'
import './FulfillmentLocationPicker.css'

interface Props {
  name: string
  availableFLs: FulfillmentLocation[]
  usedFLs: FulfillmentLocation[]
  required: boolean
  selectReference: React.RefObject<any>
  disabled?: boolean

  onChange(fulfillmentLocation?: FulfillmentLocation): void
}

export function Loaded(props: Props) {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<string>(props.name)

  const options = [
    {
      label: t('locations.locationEditor.fulfillmentLocationAvailable'),
      options: props.availableFLs
        .map(fl => ({
          label: label(fl, t),
          value: fl.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      label: t('locations.locationEditor.fulfillmentLocationAlreadyUsed'),
      options: props.usedFLs
        .map(fl => ({
          label: label(fl, t),
          value: fl.id,
          isDisabled: true,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ]

  const validationStatus = meta.touched
    ? meta.error
      ? ValidationStatus.Invalid
      : ValidationStatus.Valid
    : ValidationStatus.NotValidated

  const onChange = (data: Option) => {
    const fl = data
      ? clone(props.availableFLs.find(x => x.id === data.value))
      : undefined
    helpers.setValue(fl ? fl.id : '')
    props.onChange(fl)
  }

  const onBlur = () => {
    helpers.setTouched(true)
  }

  const filterOption = (option: Option, search: string) => {
    return anyWholeWordStartsWithIgnoringCase(option.label as string, search)
  }

  const value = options
    .map(o => o.options)
    .flat()
    .filter(o => o.value === field.value)

  return (
    <>
      <div className="form-group">
        <ValidatedSelect validationStatus={validationStatus}>
          <HighlightedSelect
            label={t('common.fulfillers.fulfillmentLocation')}
            name={props.name}
            value={value}
            options={options}
            onChange={onChange}
            matchProp="label"
            filterOption={filterOption}
            required={props.required}
            onBlur={onBlur}
            selectReference={props.selectReference}
            isDisabled={props.disabled}
            autosize={false}
          />
        </ValidatedSelect>
        {props.disabled ? (
          <div style={{ textAlign: 'right' }}>
            <NotEditableFLInfo />
          </div>
        ) : undefined}
        {meta.touched && <ValidationErrorMessage message={meta.error} />}
      </div>
    </>
  )
}

export function Loading(props: { value: string }) {
  const { t } = useTranslation()

  return (
    <div className="loadingWrapper">
      <TextField
        label={t('common.fulfillers.fulfillmentLocation')}
        value={props.value}
        disabled={true}
      />
      <span className="preloaderWrapper">
        <Preloader small={true} />
      </span>
    </div>
  )
}

function NotEditableFLInfo() {
  const [showPopover, setShowPopover] = React.useState(false)

  const togglePopover = () => setShowPopover(!showPopover)
  const closePopover = () => setShowPopover(false)

  const onButtonClick = (event: React.MouseEvent) => {
    event.preventDefault()
    togglePopover()
  }
  const onClickOutside = () => {
    closePopover()
  }

  return (
    <Tooltip
      direction="top"
      variety="popover"
      contents={
        <Trans i18nKey="locations.locationEditor.onceLocationCreated">
          Once Location is created, you need to{' '}
          <Link to="/contact-us">contact us</Link> to change the Fulfillment
          location associated with it.
        </Trans>
      }
      onClickOutside={onClickOutside}
      show={showPopover}
    >
      <AnchorButton onClick={onButtonClick}>
        <span className="fa fa-fw fa-question-circle" />
        <Trans i18nKey="locations.locationEditor.needDifferentFulfillmentLocation" />
      </AnchorButton>
    </Tooltip>
  )
}

function label(fl: FulfillmentLocation, t: TFunction) {
  const fName =
    fl.fulfiller.name ||
    `${t('commont.fulfillers.fulfiller')} ${fl.fulfiller.fulfillerId}`

  return `${fName} • ${fl.name} (${fl.id})`
}
