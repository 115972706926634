import * as coam from '@cimpress-technology/coam-sapidus'
import * as uuid from 'uuid'

const logisticsTechnologyCoamGroupId = '6525'

export async function isAdvancedUser(accessToken: string, sub: string) {
  try {
    const ltCoamGroup = await coam.group.getCoamGroupDetails(
      logisticsTechnologyCoamGroupId,
      accessToken,
      uuid.v4()
    )

    return ltCoamGroup.members.some(member => member.principal === sub)
  } catch (err) {
    return false
  }
}
