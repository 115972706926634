import { useAuth0 } from '@auth0/auth0-react'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { v4 } from 'uuid'
import { useAppContext } from '../common/AppContext'
import DevelopmentFragment from '../common/components/DevelopmentFragment'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import FragmentLoader from '../common/FragmentLoader'
import * as models from '../common/models'
import * as locationsStore from '../common/proxy/locations-store'
import NetworkPermissionsEditor from '../permissions/components/NetworkPermissionsEditor'
import NetworkDetails from './components/NetworkDetails'
import NetworkPackingFragment from './components/NetworkPackingFragment'
import { NetworkContext, Provider } from './NetworkContext'

export default function ViewNetworkPage() {
  const { getAccessTokenSilently, user } = useAuth0()
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [network, setNetwork] = React.useState<models.Network>()
  const [networkLocations, setNetworkLocations] = React.useState<
    locations.models.LocationWithLinks[]
  >([])
  const params = useParams<{ networkId: string }>()
  const match = useRouteMatch()

  const loadNetwork = React.useCallback(
    async (networkId: string) => {
      setLoading(true)
      const accessToken = await getAccessTokenSilently()

      const fetchedNetwork = await locationsStore.getNetwork(
        accessToken,
        user?.sub ?? '',
        networkId
      )

      if (!fetchedNetwork) {
        return
      }

      const allLocations = await locationsStore.getMultipleLocations(
        accessToken,
        fetchedNetwork.apiNetwork.logisticsLocationIds
      )

      setNetworkLocations(allLocations)
      setNetwork(fetchedNetwork)
      setLoading(false)
    },
    [getAccessTokenSilently, user]
  )

  React.useEffect(() => {
    const fetchData = async () => {
      await loadNetwork(params.networkId)
    }
    fetchData()
  }, [loadNetwork, params.networkId])

  if (loading) {
    return <Preloader />
  }

  if (!network) {
    return <FourOhFourPage />
  }

  const reloadNetwork = async () => {
    await loadNetwork(params.networkId)
  }

  const updateNetwork = async (
    change:
      | locations.models.LogisticsNetworkWithLinks
      | ((n: locations.models.LogisticsNetworkWithLinks) => void)
  ): Promise<void> => {
    const accessToken = await getAccessTokenSilently()
    await locationsStore.updateNetwork(accessToken, network.apiNetwork, change)
    await reloadNetwork()
  }

  const linkResourceToNetwork = async (
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => {
    const accessToken = await getAccessTokenSilently()

    return locations.linkResourceToNetworkGroup(
      accessToken,
      v4(),
      network.apiNetwork.id,
      resourceId,
      resourceType
    )
  }

  const removeLocationFromNetwork = async (
    logisticsLocationId: string,
    networkId: string
  ): Promise<void> => {
    const accessToken = await getAccessTokenSilently()
    const sub = user?.sub ?? ''
    await locationsStore.removeLocationFromNetwork(
      accessToken,
      sub,
      logisticsLocationId,
      networkId
    )
    await reloadNetwork()
  }
  const context: NetworkContext = {
    network,
    updateNetwork,
    linkResourceToNetwork,
    locations: networkLocations,
    removeLocationFromNetwork,
  }

  return (
    <Provider value={context}>
      <Switch>
        <Route path={`${match.url}/calendars`}>
          <FragmentLoader src="https://calendars-ui.qp.cimpress.io/Fragment.js" />
        </Route>
        <Route path={`${match.path}/user-management`}>
          <main className={`App-content flex-vertical ${containerType}`}>
            <BreadcrumbsItem to="/">
              {t('common.logisticsLocations')}
            </BreadcrumbsItem>
            <NetworkPermissionsEditor />
          </main>
        </Route>
        <Route path={`${match.url}/packing`}>
          <main className={`App-content ${containerType}`}>
            <NetworkPackingFragment />
          </main>
        </Route>
        <Route path={`${match.url}/fragments`}>
          <DevelopmentFragment />
        </Route>
        <Route path={`${match.path}`}>
          <main className={`App-content flex-vertical ${containerType}`}>
            <NetworkDetails />
          </main>
        </Route>
      </Switch>
    </Provider>
  )
}
