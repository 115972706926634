import { useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'
import Preloader from '../../../common/components/Preloader'
import * as models from '../../../common/models'
import {
  getAllNetworks,
  getAllLightLocations,
} from '../../../common/proxy/locations-store'
import * as LocationsContext from './LocationsContext'
import NoLocationsPage from './NoLocationsPage'
import SelectLocationPage from './SelectLocationPage'

export default function SelectLocationPageContainer() {
  return (
    <Wrapper>
      <SelectLocationPageContainerInner />
    </Wrapper>
  )
}

function SelectLocationPageContainerInner() {
  const { getAccessTokenSilently, user } = useAuth0()
  const [loading, setLoading] = React.useState(false)
  const [locations, setLocations] = React.useState<models.LightLocation[]>([])
  const [networks, setNetworks] = React.useState<models.Network[]>([])

  const reloadLocations = React.useCallback(async () => {
    const accessToken = await getAccessTokenSilently()
    const sub = user?.sub ?? ''
    setLoading(true)
    const [allLocations, allNetworks] = await Promise.all([
      getAllLightLocations(accessToken, sub),
      getAllNetworks(accessToken, sub),
    ])
    setLocations(Array.from(allLocations.values()))
    setNetworks(allNetworks)
    setLoading(false)
  }, [getAccessTokenSilently, user])

  React.useEffect(() => {
    reloadLocations()
  }, [reloadLocations])

  if (loading) {
    return <Preloader />
  }

  if (locations.length === 0) {
    return <NoLocationsPage />
  }

  return (
    <LocationsContext.Provider
      value={{
        networks,
        reloadLocations,
        locations,
      }}
    >
      <SelectLocationPage />
    </LocationsContext.Provider>
  )
}

function Wrapper(props: { children: React.ReactNode }) {
  return <main className="App-content container">{props.children}</main>
}
