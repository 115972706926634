import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import * as React from 'react'
import * as models from '../common/models'
import { useLogisticsLocation } from '../locations/LocationContext'
import LocationEditor from '../locations/LocationEditor'
import { NetworkBreadCrumbItem } from './components/RouterWithBreadcrumbs'
import { SnackbarController } from './components/SnackbarController'
import FourOhFourPage from './FourOhFourPage'
import { logError } from './logger'
import { ServerError } from './models'
import { useAppContext } from './AppContext'

export default function EditLocationPageContainer() {
  const { user } = useAuth0()
  const { containerType } = useAppContext()
  const { t } = useTranslation()
  const [saving, setSaving] = React.useState(false)
  const [serverError, setServerError] = React.useState<ServerError>()
  const { logisticsLocation, updateLocation } = useLogisticsLocation()
  const history = useHistory()

  if (!logisticsLocation.editable) {
    return <FourOhFourPage />
  }

  const onSave = async (editedLocation: models.Location) => {
    setSaving(true)
    try {
      await updateLocation(editedLocation)
      SnackbarController.show(
        t('locations.logisticsLocationHasBeenUpdated'),
        'success'
      )
      history.push('/location/' + logisticsLocation.id)
    } catch (error) {
      logError(user, 'Error when saving location', error)
      setServerError(error.response.data)
      setSaving(false)
    }
  }

  const onCancel = () => {
    if (history.length === 1) {
      history.replace('')
    } else {
      history.goBack()
    }
  }

  return (
    <main className={`App-content ${containerType} flex-vertical`}>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <NetworkBreadCrumbItem />
      <BreadcrumbsItem to={`/location/${logisticsLocation.id}`}>
        {logisticsLocation.name}
      </BreadcrumbsItem>
      <LocationEditor
        location={logisticsLocation}
        saving={saving}
        onSave={onSave}
        serverError={serverError}
        onCancel={onCancel}
      />
    </main>
  )
}
