import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@cimpress/react-components'
import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Preloader from '../common/components/Preloader'
import FourOhFourPage from '../common/FourOhFourPage'
import * as models from '../common/models'
import { getNetwork } from '../common/proxy/locations-store'
import {
  createReturnAddress,
  CreateReturnAddress,
  deleteReturnAddress,
  getCarriersByNetwork,
  getReturnAddresses,
  ReturnAddress,
  updateReturnAddress,
} from '../common/proxy/networks-store'
import DeleteReturnAddressModal from './DeleteReturnAddressModal'
import ReturnAddressesList from './ReturnAddressesList'
import EditReturnAddressModal, { Modals } from './ReturnAddressModal'

interface Props
  extends RouteComponentProps<{
    networkId: string
  }> {
  children?: React.ReactNode
}

export default function ReturnAddressesContainer(props: Props) {
  const { getAccessTokenSilently, user } = useAuth0()
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [network, setNetwork] = React.useState<models.Network>()
  const [returnAddresses, setReturnAddresses] = React.useState<ReturnAddress[]>(
    []
  )
  const [selectedReturnAddress, setSelectedReturnAddress] = React.useState<
    ReturnAddress
  >()

  const [carriers, setCarriers] = React.useState<string[]>([])

  const [modal, setModal] = React.useState<Modals>()
  const {
    match: {
      params: { networkId },
    },
  } = props
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const accessToken = await getAccessTokenSilently()
      const [logisticsNetwork, allAddresses] = await Promise.all([
        getNetwork(accessToken, user?.sub ?? '', networkId),
        getReturnAddresses(networkId),
      ] as const)

      setNetwork(logisticsNetwork)
      setReturnAddresses(allAddresses)

      setCarriers(await getCarriersByNetwork(networkId))

      setLoading(false)
    }
    fetchData()
  }, [getAccessTokenSilently, networkId, user])

  if (loading) {
    return <Preloader />
  }

  if (!network) {
    return <FourOhFourPage />
  }

  const onClickAdd = () => {
    setSelectedReturnAddress(undefined)
    setModal('add')
  }

  const onClickEdit = (returnAddress: ReturnAddress) => {
    setSelectedReturnAddress(returnAddress)
    setModal('edit')
  }
  const onClickDelete = (returnAddress: ReturnAddress) => {
    setSelectedReturnAddress(returnAddress)
    setModal('delete')
  }

  const onClickClose = () => {
    setSelectedReturnAddress(undefined)
    setModal(undefined)
  }

  const onSaveReturnAddress = async (returnAddress: CreateReturnAddress) => {
    if (modal === 'add') {
      await createReturnAddress(returnAddress)
    } else {
      await updateReturnAddress(returnAddress as ReturnAddress)
    }
    setReturnAddresses(await getReturnAddresses(props.match.params.networkId))
    setModal(undefined)
  }

  const onDeleteAddress = async () => {
    await deleteReturnAddress(selectedReturnAddress!.id)
    setReturnAddresses(await getReturnAddresses(props.match.params.networkId))
  }

  const activeModal =
    modal === 'delete' ? (
      <DeleteReturnAddressModal
        returnAddress={selectedReturnAddress!}
        onClose={onClickClose}
        onDelete={onDeleteAddress}
      />
    ) : (
      <EditReturnAddressModal
        returnAddress={selectedReturnAddress}
        modal={modal}
        carriers={carriers.map(c => ({ label: c, value: c }))}
        onClickClose={onClickClose}
        onDeleteAddress={onDeleteAddress}
        onSaveAddress={onSaveReturnAddress}
      />
    )

  return (
    <main className="App-content container flex-vertical">
      <BreadcrumbsItem to="/">{t('common.logistics')}</BreadcrumbsItem>
      <BreadcrumbsItem to="/networks">
        {network.apiNetwork.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('networks.returnAddress.title')}
      </BreadcrumbsItem>
      <div className="row vertical-align" style={{ marginBottom: '20px' }}>
        <div className="col-xs-5 col-lg-5">
          <h4>{t('networks.returnAddress.title')}</h4>
        </div>
        <div className="col-xs-7 col-lg-7 text-right">
          <Button type="primary" onClick={onClickAdd}>
            <Trans i18nKey="networks.returnAddress.addAddress">
              Add <span className="hidden-xs">address</span>
            </Trans>
          </Button>
        </div>
      </div>
      <ReturnAddressesList
        returnAddresses={returnAddresses}
        onDelete={onClickDelete}
        onEdit={onClickEdit}
      />
      {modal && activeModal}
    </main>
  )
}
