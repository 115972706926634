import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import { connect, FormikContextType } from 'formik'
import React from 'react'
import { logError } from '../../common/logger'
import { get } from '../helpers/deepBracketNotation'

interface Props extends WithAuth0Props {
  focusableFields: Array<{ name: string; ref: React.RefObject<any> }>
}

class ErrorFocus extends React.Component<
  Props & { formik: FormikContextType<{}> }
> {
  public componentDidUpdate(
    prevProps: Props & { formik: FormikContextType<{}> }
  ) {
    const { isSubmitting, isValidating } = prevProps.formik
    if (isSubmitting && !isValidating) {
      for (const element of this.props.focusableFields) {
        if (get(this.props.formik.errors, element.name)) {
          if (element.ref.current) {
            element.ref.current.focus()
          } else {
            logError(
              this.props.auth0.user,
              `Unable to focus element ${element.name}`
            )
          }

          return
        }
      }
    }
  }

  public render() {
    return null
  }
}

export default withAuth0(connect<Props>(ErrorFocus))
