import { SpecVisitor, traverse } from '../../carrier-configuration/specVisitor'
import { clone } from '../helpers/clone'
import { Option } from '../models'
import { ConfigSpec, EnhancedCaasProfile } from '../models-carriers.js'
import * as carrierServicesProxy from './carrier-services-proxy'
import {
  addDefaultUploadsToCaasProfile,
  hideSharedSequences,
} from './profile-helpers/add-defaults-to-caas-profile'
import { enrichCaasProfile } from './profile-helpers/enrich-caas-profile'

let cache: carrierServicesProxy.ApiCaasProfile[] | undefined

export async function getCaasProfiles(
  accessToken: string
): Promise<EnhancedCaasProfile[]> {
  const originalProfiles = await fetchAllProfiles(accessToken)
  cache = originalProfiles

  const caasProfiles = originalProfiles.map(processCaasProfile)

  return caasProfiles
}

export async function getCaasProfile(
  accessToken: string,
  key: string
): Promise<EnhancedCaasProfile> {
  const caasProfiles = await fetchAllProfiles(accessToken)

  const caasProfile = caasProfiles.find(profile => profile.key === key)!

  return processCaasProfile(caasProfile)
}

async function fetchAllProfiles(accessToken: string) {
  if (cache) {
    return cache
  }
  cache = await carrierServicesProxy.getCaasProfiles(accessToken)

  return cache
}

function processCaasProfile(
  profile: carrierServicesProxy.ApiCaasProfile
): EnhancedCaasProfile {
  return hideSharedSequences(
    addDefaultUploadsToCaasProfile(enrichCaasProfile(profile))
  )
}

export function getDocumentTypeKeys(profile: EnhancedCaasProfile) {
  const documentTypeKeys: Option[] = []
  if (profile.documents) {
    documentTypeKeys.push(
      ...profile.documents.map(document => ({
        label: document.name || document.key,
        value: document.key,
      }))
    )
  }

  if (profile.additionalDocuments) {
    documentTypeKeys.push(
      ...profile.additionalDocuments.map(document => ({
        label: document.name || document.key,
        value: document.key,
      }))
    )
  }

  return documentTypeKeys
}

export function initConfigArray(specs: ConfigSpec[]): any {
  const main: ConfigSpec = {
    key: 'main',
    name: 'main',
    value: {
      valueType: 'object',
      items: specs,
    },
  }
  const config: any = {}
  const initSpecVisitor = InitSpecVisitor(config)
  traverse(main, initSpecVisitor)

  return config.main
}

const InitSpecVisitor = (config: any): SpecVisitor => {
  return {
    visitObject: (spec: ConfigSpec) => {
      config[spec.key] = {}
      if (spec.value.items) {
        spec.value.items!.forEach(item =>
          traverse(item, InitSpecVisitor(config[spec.key]))
        )
      }
    },
    visitBoolean: (spec: ConfigSpec) => {
      config[spec.key] =
        spec.value.default === undefined ? false : spec.value.default
    },
    visitString: (spec: ConfigSpec) => {
      config[spec.key] = spec.value.default
    },
    visitNumber: (spec: ConfigSpec) => {
      config[spec.key] =
        spec.value.default === undefined ? 0 : spec.value.default
    },
  }
}

export function filterAvailableCaasProfiles(
  profiles: EnhancedCaasProfile[]
): EnhancedCaasProfile[] {
  const noEnhancedProfile = profiles.every(profile => !profile.isEnhanced)

  return clone(profiles).filter(
    profile =>
      profile.isEnhanced ||
      (noEnhancedProfile && profile.carrierServices.length > 0)
  )
}
