import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

export interface Profile {
  email: string
  name: string
  picture: string
  domain: string
  sub: string
}

export const CLIENT_ID = 'iidGCVqKzo6O2yuApIkp9tUbzKWQpHxY'

export function useAuth() {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect: _loginWithRedirect,
    logout,
    user,
  } = useAuth0()

  const [nextUri, setNextUri, removeNextUri] = useLocalStorage<string>(
    'nextUri'
  )
  const history = useHistory()

  useEffect(() => {
    if (nextUri && isAuthenticated) {
      removeNextUri()
      history.push(nextUri)
    }
  })

  const loginWithRedirect = useCallback(
    (next?: string) => {
      setNextUri(next)
      _loginWithRedirect({
        redirectUri: window.location.origin,
      })
    },
    [_loginWithRedirect, setNextUri]
  )

  return {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  }
}
