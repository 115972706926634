import { CssLoader } from '@cimpress/react-components'
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient'
import Header from '@cimpress-technology/react-platform-header'
import { Loudspeaker } from '@cimpress-technology/react-loudspeaker'
import { ThroughProvider } from 'react-through'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { hot } from 'react-hot-loader/root'
import * as ReactDOM from 'react-dom'
import * as React from 'react'
import { useAuth } from '../auth'
import * as logger from '../logger'
import * as fulfillmentLocationsStore from '../../common/proxy/fulfillment-locations-store'
import { supportedLanguages } from '../../i18n'
import AuthorizationPage from '../AuthorizationPage'
import * as googleAnalytics from '../helpers/googleAnalytics'
import { hotjar } from '../hotjar'
import { reactSelectPortalId } from '../models'
import { isAdvancedUser as getIsAdvancedUser } from '../proxy/user-store'
import * as UserContext from '../UserContext'
import { MainRouter } from './RouterWithBreadcrumbs'
import { SnackbarController } from './SnackbarController'
import Container from './Container'
import generateApplinks from './app-links'
import './App.css'
import './colors.css'

function App() {
  const [t, i18n] = useTranslation()

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    user,
  } = useAuth()
  const [accessToken, setAccessToken] = React.useState('')
  const [isAdvancedUser, setIsAdvancedUser] = React.useState(false)
  const [
    enableAdvancedUserToggle,
    setEnableAdvancedUserToggle,
  ] = React.useState(false)

  React.useEffect(() => {
    const initialLoad = async () => {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
      const sub = user?.sub ?? ''
      logger.setErrorHandler(user)
      loadCache(token, sub).catch(e =>
        logger.logError(user, 'Error loading cache data', e)
      )
      const [advancedUser, settings] = await Promise.all([
        getIsAdvancedUser(token, sub),
        getSettings(token, supportedLanguages),
      ])
      setIsAdvancedUser(advancedUser)
      setEnableAdvancedUserToggle(advancedUser)

      i18n.changeLanguage(settings.global.language)
      googleAnalytics.initialize(sub)
      const fullstory = (window as any).FS
      if (fullstory) {
        fullstory.identify(user?.sub, {
          displayName: user?.name,
          email: user?.email,
        })
      }

      const isFromCimpress = (user?.email || '').endsWith('cimpress.com')

      if (!isFromCimpress) {
        hotjar(1680483, 6)
      }
    }

    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect(window.location.pathname + window.location.search)

      return
    }

    initialLoad()
  }, [
    isAuthenticated,
    isLoading,
    i18n,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  ])

  const loudspeakerConf = {
    language: i18n.language,
    title: t('loudspeaker.title'),
    channelIds: ['logistics-configuration'],
  }

  const appLinks = generateApplinks(
    isAdvancedUser,
    () => setIsAdvancedUser(!isAdvancedUser),
    enableAdvancedUserToggle,
    i18n,
    t
  )

  const context = { isAdvancedUser }

  if (!accessToken) {
    return null
  }

  return (
    <Container>
      <UserContext.Provider value={context}>
        <ThroughProvider>
          <CssLoader>
            <Helmet>
              <title>Logistics Configuration</title>
            </Helmet>
            <>
              <Loudspeaker
                accessToken={accessToken}
                language={loudspeakerConf.language}
                title={loudspeakerConf.title}
                channelIds={loudspeakerConf.channelIds}
              />
              <SnackbarController />
              <div style={{ zIndex: 999 }}>
                <Header
                  responsive={true}
                  appTitle={
                    <Link to="/">
                      <Trans i18nKey="title" />
                    </Link>
                  }
                  profile={user}
                  authToken={accessToken}
                  isLoggedIn={isAuthenticated}
                  onLogInClicked={loginWithRedirect}
                  onLogOutClicked={logout}
                  appLinks={appLinks}
                  onCurrentTenantChange={() => undefined}
                />
              </div>
              {isAuthenticated ? <MainRouter /> : <AuthorizationPage />}
            </>
          </CssLoader>
        </ThroughProvider>
        <ReactSelectMenuPortal />
      </UserContext.Provider>
    </Container>
  )
}

function ReactSelectMenuPortal() {
  const menu = (
    <div
      id={reactSelectPortalId}
      className={reactSelectPortalId}
      style={{
        zIndex: 9999,
        position: 'absolute',
        top: '0px',
      }}
      tabIndex={-1}
    />
  )

  return ReactDOM.createPortal(menu, document.body)
}

async function loadCache(accessToken: string, sub: string) {
  await fulfillmentLocationsStore.getAll(accessToken, sub)
}

export default hot(App)
