import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import { Button } from '@cimpress/react-components'
import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Link, RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'
import * as React from 'react'
import { SnackbarController } from '../../common/components/SnackbarController'
import { clone } from '../../common/helpers/clone'
import { logError } from '../../common/logger'
import {
  Location,
  Option,
  SequenceDefinition,
  Uploads,
} from '../../common/models'
import { SequenceDefinitionPair } from '../../common/models-carriers'
import { updateCarrierAccount } from '../../common/proxy/carrier-accounts-store'
import { patchSequence } from '../../common/proxy/sequences-store'
import * as sequenceHelpers from './sequences/helpers'
import MultipleSequencesEditor from './sequences/MultipleSequencesEditor'
import { isSequenceValid } from './sequences/sequenceEditorValidation'
import { isUploadValid } from './uploads/uploadEditorValidation'
import { UploadsEditContainer } from './uploads/UploadsEditContainer'

interface ContainerProps {
  header: string
  children: React.ReactNode
  footer: React.ReactNode
}

function Container(props: ContainerProps) {
  return (
    <div className="container">
      <div className="row" style={{ marginBottom: '32px' }}>
        <div
          className="col-md-9 col-lg-9 align-self-center"
          style={{ float: 'none', margin: 'auto' }}
        >
          <h1>{props.header}</h1>
          <div className="card">
            <div className="card-block">{props.children}</div>
          </div>
          <div style={{ paddingTop: '16px', paddingBottom: '32px' }}>
            <span style={{ float: 'right' }}>{props.footer}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface Props
  extends RouteComponentProps<{
    locationId: string
    carrierAccountId: string
  }> {
  logisticsLocation: Location
  carrierAccount: carrierAccounts.models.CarrierAccount
  documentTypeKeys: Option[]
  defaultDocumentUploads?: Uploads[]
  sequenceDefinitionPairs: SequenceDefinitionPair[]
}

interface State {
  uploads: Uploads[]
  selectedUploadOption: carrierAccounts.models.UploadType

  sequenceDefinitions: SequenceDefinition[]
  areSequencesValid: boolean[]
  areUploadsValid: boolean[]
  highlightAllErrors: boolean
}

class UploadsEditorInner extends React.Component<
  Props & WithTranslation & WithAuth0Props,
  State
> {
  public readonly state = {
    uploads: clone(this.props.carrierAccount.uploads),
    selectedUploadOption: 'email' as carrierAccounts.models.UploadType,
    sequenceDefinitions: this.props.sequenceDefinitionPairs.map(pair =>
      clone(pair.sequenceDefinition)
    ),
    areSequencesValid: this.props.sequenceDefinitionPairs.map(sd => true),
    areUploadsValid: this.props.carrierAccount.uploads.map(u => true),
    highlightAllErrors: false,
  }

  public render() {
    const sequences = this.props.sequenceDefinitionPairs.map(sd => sd.sequence)

    const areDocumentUploadsEditable =
      this.props.defaultDocumentUploads === undefined ||
      this.props.defaultDocumentUploads
        .map(isUploadValid)
        .some(u => u === false)

    const footer = (
      <>
        <Link
          to={`../../carrier-accounts/${this.props.carrierAccount.id}`}
          className="btn btn-default"
        >
          Cancel
        </Link>{' '}
        <Button
          type="primary"
          onClick={this.save}
          disabled={[
            ...this.state.areSequencesValid,
            ...this.state.areUploadsValid,
          ].some(value => value === false)}
        >
          Save Changes
        </Button>
      </>
    )

    return (
      <Container
        header={this.props.t('carrierAccounts.uploads.editAccountUploads', {
          name: this.props.carrierAccount.name,
        })}
        footer={footer}
      >
        <UploadsEditContainer
          caasProfileKey={this.props.carrierAccount.carrierKey}
          uploads={this.props.carrierAccount.uploads}
          documentTypeKeys={this.props.documentTypeKeys}
          edit={areDocumentUploadsEditable}
          onChange={this.onUploadsChange}
          canAddOrRemoveUpload={true}
          validateAll={this.state.highlightAllErrors}
        />
        {this.props.sequenceDefinitionPairs.some(pair =>
          sequenceHelpers.showAsUploadSequence(pair.sequence)
        ) ? (
          <MultipleSequencesEditor
            sequenceTypeToShow={'upload'}
            sequences={sequences}
            onSequenceDefinitionChange={this.onSequenceDefinitionChange}
            sequenceDefinitions={this.state.sequenceDefinitions}
            areValid={this.state.areSequencesValid}
          />
        ) : undefined}
      </Container>
    )
  }

  private onSequenceDefinitionChange = (
    sequenceDefinitions: SequenceDefinition[],
    areSequencesValid: boolean[]
  ) => {
    this.setState({ sequenceDefinitions, areSequencesValid })
  }

  private onUploadsChange = (
    uploads: Uploads[],
    areUploadsValid: boolean[]
  ) => {
    this.setState({ uploads, areUploadsValid })
  }

  private save = async () => {
    this.setState({
      highlightAllErrors: true,
    })

    const documentsContainErrors = [
      ...this.state.uploads.map(isUploadValid),
      ...this.state.sequenceDefinitions!.map(isSequenceValid),
    ].some(value => value === false)

    if (documentsContainErrors) {
      return
    }

    try {
      const carrierAccount: carrierAccounts.models.CarrierAccount = {
        ...this.props.carrierAccount,
        uploads: this.state.uploads,
      }

      const patches = this.state.sequenceDefinitions
        .map((sd, i) => ({
          id: sd.id!,
          patch: sequenceHelpers.createPatchSequence(
            sd,
            this.props.sequenceDefinitionPairs[i].sequenceDefinition
          ),
        }))
        .filter(ps => Object.entries(ps.patch).length !== 0)

      const accessToken = await this.props.auth0.getAccessTokenSilently()
      await Promise.all(
        patches.map(p =>
          patchSequence(accessToken, this.props.auth0.user, p.id, p.patch)
        )
      )
      await updateCarrierAccount(
        accessToken,
        this.props.carrierAccount,
        carrierAccount
      )

      SnackbarController.show(
        this.props.t('carrierAccounts.uploads.updateSucceeded', {
          name: carrierAccount.name,
        }),
        'success'
      )
      this.props.history.push(
        `/location/${this.props.logisticsLocation.id}/carrier-accounts/${carrierAccount.id}`
      )
    } catch (err) {
      SnackbarController.show(
        this.props.t('carrierAccounts.uploads.updateFailed', {
          name: this.props.carrierAccount.name,
        }),
        'danger'
      )
      logError(
        this.props.auth0.user,
        'Carrier Account uploads update failed',
        err
      )
    }
  }
}

export const UploadsEditor = withAuth0(withTranslation()(UploadsEditorInner))
