import { models as coamModels } from '@cimpress-technology/coam-sapidus'
import * as client from '@cimpress-technology/logistics-configuration-client'
import { Table } from '@cimpress/react-components'
import moment from 'moment-timezone'
import * as React from 'react'
import ReactJsonView, { CollapsedFieldProps } from 'react-json-view'
import { Link } from 'react-router-dom'
import * as uuid from 'uuid'
import * as jsonPatch from 'fast-json-patch'
import { useAuth0 } from '@auth0/auth0-react'

interface Props {
  resourceId: string
  resourceType: string
}

interface AuditRowDisplay {
  resource_id: string
  resource_type: string
  user: string
  data: any
  patch: any
  action: string
}
export default function AuditTable(props: Props) {
  const { getAccessTokenSilently } = useAuth0()
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState<AuditRowDisplay[]>([])

  React.useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently()
      setLoading(true)
      const actions = await client.audits.getActions(
        accessToken,
        uuid.v4(),
        props.resourceType as coamModels.ResourceTypes,
        props.resourceId
      )
      const diff: AuditRowDisplay[] = getDiff(actions)
      if (
        [
          coamModels.ResourceTypes.LogisticsLocation,
          coamModels.ResourceTypes.LogisticsNetwork,
        ].includes(props.resourceType as coamModels.ResourceTypes)
      ) {
        const permissions = await client.audits.getActions(
          accessToken,
          uuid.v4(),
          `${props.resourceType}-permissions` as coamModels.ResourceTypes,
          props.resourceId
        )

        diff.push(
          ...permissions.map(p => ({
            ...p,
            action: p.action,
            patch: p.data,
            data: undefined,
          }))
        )
      }
      setData(diff)
      setLoading(false)
    }

    fetchData()
  }, [getAccessTokenSilently, props.resourceId, props.resourceType])

  const columns = [
    {
      Header: 'Timestamp',
      accessor: 'created_at',
      width: 250,
      Cell: (row: any) => moment(row.original.created_at).format(),
    },
    { Header: 'Action', accessor: 'action', width: 200 },
    { Header: 'User', accessor: 'user', width: 300 },
    {
      Header: 'Applied change',
      Cell: (row: any) => {
        if (row.original.action === 'WORKING-DAYS-UPDATE') {
          return (
            <>
              <Link to={row.original.data.workingDaysCalendar.id}>
                Go to working days calendar history
              </Link>
              <ReactJsonView
                collapsed={true}
                src={row.original.patch}
                enableClipboard={false}
                name={null}
                displayDataTypes={false}
              />
            </>
          )
        }

        if (row.original.noPatch) {
          return null
        }

        const shouldCollapse = (field: CollapsedFieldProps) => {
          if (field.type === 'array') {
            return (field.src as any).length > 3
          }

          return false
        }

        return (
          <ReactJsonView
            shouldCollapse={shouldCollapse}
            src={row.original.patch}
            enableClipboard={false}
            name={null}
            displayDataTypes={false}
          />
        )
      },
    },
    {
      Header: 'Current Status',
      Cell: (row: any) => (
        <ReactJsonView
          collapsed={true}
          src={row.original.data}
          enableClipboard={false}
          name={null}
          displayDataTypes={false}
        />
      ),
    },
  ]

  return (
    <Table
      loading={loading}
      columns={columns}
      data={data}
      sortable={false}
      resizable={false}
      showPagination={false}
      pageSize={data.length > 0 ? data.length : 10}
      noDataText="No records found"
      defaultSorted={[{ id: 'created_at', desc: true }]}
    />
  )
}

function getDiff(data: any[]) {
  return data.reduceRight((acc, curr, index, array) => {
    if (index === array.length - 1) {
      return [{ ...curr, noPatch: true }]
    }

    const prev = array[index + 1]
    const patch = jsonPatch.compare(prev.data, curr.data)

    return [{ ...curr, patch }, ...acc]
  }, [])
}
