import { User } from '@auth0/auth0-react'
import axios from 'axios'
import { logError } from '../logger'
import { ApiSequenceDefinition } from '../models'

const SEQUENCES_URL = 'https://sequences.logistics.cimpress.io/api/v3'

export const createSequence = async (
  accessToken: string,
  user: User | undefined,
  sequence: ApiSequenceDefinition
): Promise<string> => {
  try {
    const response = (
      await axios.post(SEQUENCES_URL + '/sequences', sequence, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data

    if (!response.sequenceId) {
      throw new Error('Response does not contain sequenceId')
    }

    return response.sequenceId
  } catch (e) {
    logError(
      user,
      `Error when creating sequence ${JSON.stringify(sequence)}`,
      e
    )
    throw e
  }
}

export const deleteSequence = async (
  accessToken: string,
  user: User | undefined,
  sequenceId: string
): Promise<void> => {
  try {
    await axios.delete(SEQUENCES_URL + `/sequences/${sequenceId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  } catch (e) {
    logError(user, `Error when deleting sequence ${sequenceId}`, e)
    throw e
  }
}

export const getSequenceDirect = async (
  accessToken: string,
  user: User | undefined,
  url: string
): Promise<ApiSequenceDefinition> => {
  try {
    return (
      await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data
  } catch (e) {
    logError(user, 'Error when getting location', e)
    throw e
  }
}

export const patchSequence = async (
  accessToken: string,
  user: User | undefined,
  sequenceId: string,
  body: any
): Promise<void> => {
  try {
    await axios.patch(SEQUENCES_URL + `/sequences/${sequenceId}`, body, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    return
  } catch (e) {
    logError(user, `Error when creating sequence ${JSON.stringify(body)}`, e)
    throw e
  }
}
