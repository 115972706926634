import SumoLogger from 'sumo-logger'

// type definition requires onSuccess and onError callbacks, but they are not mandatory
const config: any = {
  endpoint:
    'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV388IXIjyoe7ywl59lAHhLO571-En_zc_ibY0hENjJV5sBwnUAu-W0U7dBTbyT-WA71PMoejmBO7k9K-aZL1eVNQH5ZrI2XwCqT6zaa19OMXA==',
  sourceName: 'sapidus_ui_production',
  sourceCategory: '263302/CT/Sapidus/ui/production',
}
const logger = new SumoLogger(config)
const sendError = process.env.NODE_ENV === 'production'

export function log(data: {
  level: number
  user?: any
  error?: Error
  message: string
  file?: string
  line?: number
  column?: number
}) {
  const { error, ...rest } = data

  if (sendError) {
    logger.log({
      url: clearSensitiveData(window.location.href),
      error: data?.error
        ? JSON.parse(clearSensitiveData(JSON.stringify(data.error)))
        : undefined,
      err: 'BrowserJsException',
      ...rest,
    })
  } else {
    // eslint-disable-next-line no-console
    console.log(data?.message, data?.error)
  }
}

export function flushLogs() {
  if (sendError) {
    window.addEventListener('beforeunload', () => {
      logger.flushLogs()
    })
  }
}

export function clearSensitiveData(data: string) {
  function replacer(match: string, p1: string) {
    return p1 + 'REDACTED'
  }

  return data
    .replace(/(Bearer )[A-Za-z0-9=_.-]*/gi, replacer)
    .replace(/(access_token=)[A-Za-z0-9=_.-]*/gi, replacer)
}
