import * as React from 'react'
import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as models from '../common/models'

export interface NetworkContext {
  network: models.Network
  updateNetwork: models.NetworkMutator
  linkResourceToNetwork: (
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>
  locations: locations.models.LocationWithLinks[]
  removeLocationFromNetwork: (
    logisticsLocationId: string,
    networkId: string
  ) => Promise<void>
}

export const Context = React.createContext<NetworkContext>({} as NetworkContext)

export const Provider = Context.Provider

export function useNetwork() {
  return React.useContext(Context)
}
