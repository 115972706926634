import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import * as uuid from 'uuid'
import { clone } from '../helpers/clone'
import * as models from '../models'
import { apiFLsToFLs, getAll as getFLs } from './fulfillment-locations-store'

const tagsEnabledFlag = 'enable-tags-in-pickup-calendar'
const showDeliveryCalendar = 'enable-delivery-calendars'

async function apiLocationsToLocations(
  accessToken: string,
  sub: string,
  apiLocations: Omit<locations.models.LocationWithLinks, 'countryCalendars'>[]
): Promise<Map<string, models.LightLocation>>
async function apiLocationsToLocations(
  accessToken: string,
  sub: string,
  apiLocations: locations.models.LocationWithLinks[]
): Promise<Map<string, models.Location>> {
  const [allFLs, allowedToEdit, accessibleNetworks] = await Promise.all([
    getFLs(accessToken, sub),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsLocation,
      sub,
      coam.models.LLPermissions.Update,
      `Bearer ${accessToken}`,
      sub
    ),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsNetwork,
      sub,
      coam.models.LogisticsNetworkPermissions.Read,
      `Bearer ${accessToken}`,
      sub
    ),
  ])

  const allLocations = new Map<string, models.Location>()
  apiLocations.forEach(apiLocation => {
    const location = {
      ...apiLocation,
      fulfillmentLocations: apiFLsToFLs(
        apiLocation.fulfillmentLocations,
        allFLs
      ),
      editable:
        allowedToEdit === 'all' || allowedToEdit.includes(apiLocation.id),
      tagsEnabled: false,
      showDeliveryCalendar: false,
      ...(apiLocation._links &&
        apiLocation._links.network && {
          networkIsAccessible:
            accessibleNetworks === 'all' ||
            accessibleNetworks.includes(apiLocation._links.network.id),
        }),
    }

    allLocations.set(apiLocation.id, location)
  })

  return allLocations
}

export const getAllLocations = async (
  accessToken: string,
  sub: string
): Promise<Map<string, models.Location>> => {
  return apiLocationsToLocations(
    accessToken,
    sub,
    await locations.getLocations(accessToken, uuid.v4())
  )
}

export const getAllLightLocations = async (
  accessToken: string,
  sub: string
): Promise<Map<string, models.LightLocation>> => {
  return apiLocationsToLocations(
    accessToken,
    sub,
    await locations.getLocations(accessToken, uuid.v4(), {
      excludes: 'countryCalendars',
    })
  )
}

export const getLocation = async (
  accessToken: string,
  sub: string,
  id: string
): Promise<models.Location | undefined> => {
  const correlationId = uuid.v4()

  const [apiLocation, flags] = await Promise.all([
    locations.getLocation(accessToken, correlationId, id),
    locations.getAllFlagsByLocation(accessToken, correlationId, id),
  ] as const)
  if (!apiLocation) {
    return undefined
  }

  const location = (
    await apiLocationsToLocations(accessToken, sub, [apiLocation])
  ).get(apiLocation.id)!
  location.tagsEnabled = flags[tagsEnabledFlag]
    ? flags[tagsEnabledFlag].value
    : false
  location.showDeliveryCalendar = flags[showDeliveryCalendar]
    ? flags[showDeliveryCalendar].value
    : false

  return location
}

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined
}

export const getMultipleLocations = async (
  accessToken: string,
  ids: string[]
) => {
  const locationPromises = []

  for (const id of ids) {
    locationPromises.push(locations.getLocation(accessToken, uuid.v4(), id))
  }

  const apiLocations = await Promise.all(locationPromises)

  return apiLocations.filter(notUndefined)
}

export const updateLocation = async (
  accessToken: string,
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId?: string
): Promise<void> => {
  await locations.updateLocation(
    accessToken,
    correlationId || uuid.v4(),
    id,
    etag,
    patch
  )
}

export const createLocation = async (
  accessToken: string,
  location: models.Location
): Promise<string> => {
  const correlationId = uuid.v4()
  const locationId = await locations.createLocation(
    accessToken,
    correlationId,
    mapLocationToApiLocation(location)
  )

  return locationId
}

export async function addUser(
  accessToken: string,
  locationId: string,
  email: string,
  role: string
) {
  await locations.addUser(accessToken, uuid.v4(), locationId, email, role)
}

export async function removeUser(
  accessToken: string,
  locationId: string,
  email: string
) {
  await locations.removeUser(accessToken, uuid.v4(), locationId, email)
}

export async function addLocationToNetwork(
  accessToken: string,
  locationId: string,
  networkId: string
) {
  const correlationId = uuid.v4()

  const network = (await locations.getLogisticsNetwork(
    accessToken,
    correlationId,
    networkId
  ))!

  return updateNetwork(
    accessToken,
    network,
    n => n.logisticsLocationIds.push(locationId),
    correlationId
  )
}

export async function removeLocationFromNetwork(
  accessToken: string,
  sub: string,
  logisticsLocationId: string,
  networkId: string
): Promise<void> {
  const correlationId = uuid.v4()
  const location = await getLocation(accessToken, sub, logisticsLocationId)
  if (location === undefined) {
    return
  }

  const network = (await locations.getLogisticsNetwork(
    accessToken,
    correlationId,
    networkId
  ))!
  await updateNetwork(
    accessToken,
    network,
    n =>
      (n.logisticsLocationIds = n.logisticsLocationIds.filter(
        llId => llId !== logisticsLocationId
      )),
    correlationId
  )
  await updateLocation(accessToken, location.id, location.etag, [
    { op: 'add', path: '/accountId', value: location.accountId },
  ])
}

export async function updateNetwork(
  accessToken: string,
  network: locations.models.LogisticsNetworkWithLinks,
  change:
    | locations.models.LogisticsNetworkWithLinks
    | ((n: locations.models.LogisticsNetworkWithLinks) => void),
  correlationId?: string
): Promise<void> {
  let copy
  if (typeof change === 'function') {
    copy = clone(network)
    change(copy)
  } else {
    copy = change
  }
  const patches = jsonPatch
    .compare(network, copy)
    .filter(patch => !patch.path.includes('href'))

  if (patches.length > 0) {
    await locations.updateLogisticsNetwork(
      accessToken,
      correlationId ?? uuid.v4(),
      network.id,
      network.etag,
      patches
    )
  }
}

export const getAllNetworks = async (
  accessToken: string,
  sub: string
): Promise<models.Network[]> => {
  const [networks, allowedToEdit] = await Promise.all([
    locations.getLogisticsNetworks(accessToken, uuid.v4()),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.LogisticsNetwork,
      sub,
      coam.models.LLPermissions.Update,
      `Bearer ${accessToken}`,
      sub
    ),
  ])

  return networks.map(network => ({
    apiNetwork: network,
    editable:
      allowedToEdit === 'all' || allowedToEdit.indexOf(network.id) !== -1,
  }))
}

export const getNetwork = async (
  accessToken: string,
  sub: string,
  networkId: string
): Promise<models.Network | undefined> => {
  const [network, editable] = await Promise.all([
    locations.getLogisticsNetwork(accessToken, uuid.v4(), networkId),
    coam.auth.isAllowed(
      networkId,
      coam.models.ResourceTypes.LogisticsNetwork,
      sub,
      coam.models.LogisticsNetworkPermissions.Update,
      `Bearer ${accessToken}`,
      sub
    ),
  ] as const)

  if (!network) {
    return undefined
  }

  return { apiNetwork: network, editable }
}

function mapLocationToApiLocation(
  location: models.Location
): locations.models.CreateLocation {
  const apiFls = location.fulfillmentLocations.map(fl => ({
    id: fl.id,
  }))

  return {
    address: location.address,
    contact: location.contact,
    localeSettings: location.localeSettings,
    name: location.name,
    fulfillmentLocations: apiFls,
    transitCalendars: location.transitCalendars,
    pickupCalendars: location.pickupCalendars,
    carrierAccounts: location.carrierAccounts,
    accountId: location.accountId,
  }
}
