import { User } from '@auth0/auth0-react'
import * as jsonPatch from 'fast-json-patch'
import * as proxy from './backend-proxy'

export function createIncompleteCarrierAccount(
  accessToken: string,
  user: User | undefined,
  locationId: string,
  incompleteCarrierAccount: proxy.IncompleteCarrierAccount
): Promise<string> {
  return proxy.createIncompleteCarrierAccount(
    accessToken,
    user,
    locationId,
    incompleteCarrierAccount
  )
}

export function updateIncompleteCarrierAccount(
  accessToken: string,
  locationId: string,
  oldIncompleteCarrierAccount: proxy.IncompleteCarrierAccount,
  newIncompleteCarrierAccount: proxy.IncompleteCarrierAccount
): Promise<string> {
  return proxy.updateIncompleteCarrierAccount(
    accessToken,
    locationId,
    oldIncompleteCarrierAccount.id!,
    oldIncompleteCarrierAccount.etag!,
    jsonPatch.compare(oldIncompleteCarrierAccount, newIncompleteCarrierAccount)
  )
}

export function getIncompleteCarrierAccounts(
  accessToken: string,
  user: User | undefined,
  locationId: string
): Promise<proxy.IncompleteCarrierAccount[]> {
  return proxy.getIncompleteCarrierAccounts(accessToken, user, locationId)
}

export function getIncompleteCarrierAccount(
  accessToken: string,
  user: User | undefined,
  locationId: string,
  incompleteCarrierAccountId: string
): Promise<proxy.IncompleteCarrierAccount> {
  return proxy.getIncompleteCarrierAccount(
    accessToken,
    user,
    locationId,
    incompleteCarrierAccountId
  )
}

export function deleteIncompleteCarrierAccount(
  accessToken: string,
  locationId: string,
  carrierAccountId: string
): Promise<string> {
  return proxy.deleteIncompleteCarrierAccount(
    accessToken,
    locationId,
    carrierAccountId
  )
}
