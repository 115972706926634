import { User } from '@auth0/auth0-react'
import { Account } from '../../common/models'
import * as proxy from './account-proxy'

export function getAccounts(
  accessToken: string,
  user: User | undefined
): Promise<Account[]> {
  return proxy.getAccounts(user, accessToken)
}
export function getAccount(
  accessToken: string,
  user: User | undefined,
  accountId: string
): Promise<Account> {
  return proxy.getAccount(user, accessToken, accountId)
}
