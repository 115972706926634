import { User } from '@auth0/auth0-react'
import * as _ from 'underscore'
import * as primitiveLogger from './primitiveLogger'

const onerror = window.onerror
// necessary for apply with arguments
// tslint:disable-next-line:only-arrow-functions
export function setErrorHandler(user: User | undefined) {
  window.onerror = function (message, url, line, column) {
    primitiveLogger.log({
      user: _.pick(user, 'email', 'sub'),
      level: 50,
      file: url,
      message: message as any,
      line,
      column,
    })

    if (onerror && typeof onerror === 'function') {
      // eslint-disable-next-line prefer-rest-params
      onerror.apply(window, arguments)
    }
  }
}

export function logError(user: User | undefined, message: string, err?: any) {
  primitiveLogger.log({
    user: _.pick(user, 'email', 'sub'),
    level: 50,
    error: err,
    message,
  })
}
