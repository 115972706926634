import { User, withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import {
  calendars,
  carrierAccounts,
} from '@cimpress-technology/logistics-configuration-client'
import { RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'
import * as React from 'react'
import { PickupCalendar } from '../../../calendars/models'
import { SnackbarController } from '../../../common/components/SnackbarController'
import { clone } from '../../../common/helpers/clone'
import { getDefaultPickupCalendar } from '../../../common/helpers/pickups'
import { logError } from '../../../common/logger'
import { Location, SequenceDefinition, Uploads } from '../../../common/models'
import { EnhancedCaasProfile } from '../../../common/models-carriers'
import { IncompleteCarrierAccount } from '../../../common/proxy/backend-proxy'
import {
  createIncompleteCarrierAccount,
  deleteIncompleteCarrierAccount,
  updateIncompleteCarrierAccount,
} from '../../../common/proxy/backend-store'
import { createCarrierAccountForLocation } from '../../../common/proxy/carrier-accounts-store'
import {
  addSequenceToLocationCoamGroup,
  createSequenceForLocation,
  deleteSequenceFromLocation,
} from '../../../common/proxy/sequences-store'
import CarrierAccountCard from './CarrierAccountCard'
import {
  CarrierAccountData,
  CarrierSelection,
  ExistingCarrierAccountData,
  getCarrierAccountData,
  getExistingCarrierAccountData,
} from './carrierAccountData'
import CarrierAccountStep, {
  showCarrierAccountStep,
} from './CarrierAccountStep'
import CarrierAccountStepper from './CarrierAccountStepper'
import CarrierSelectModalContainer from './CarrierSelectModalContainer'
import DocumentsStep, { showDocumentsStep } from './DocumentsStep'
import GetCarrierNameModal from './GetCarrierNameModal'
import PickupsStep from './PickupsStep'
import {
  addIndices,
  getInitialStep,
  getSurroundingSteps,
  skipSteps,
  StepDefinition,
  Steps,
} from './steps'
import SummaryStep from './SummaryStep'
import TrackingIdsStep, { showTrackingIdsStep } from './TrackingIdsStep'

interface State {
  showGetNameModal: boolean
  carrierAccountData: CarrierAccountData

  pickupCalendar: PickupCalendar
  validatedFields: Set<string>

  canAddOrRemoveUpload: boolean
  step: Steps | undefined
  maxStepReached: number

  hideCarrierSelectModal: boolean
}

export interface PreloadedData {
  logisticsLocation: Location
  existingCarrierAccountNames: string[]
  caasProfiles: EnhancedCaasProfile[]
}

export interface Props
  extends RouteComponentProps<
      {
        locationId: string
      },
      any,
      { carrierSelection: CarrierSelection }
    >,
    PreloadedData {
  existingData?: ExistingCarrierAccountData
}

class AddCarrierAccountPageContainer extends React.Component<
  Props & WithTranslation & WithAuth0Props,
  State
> {
  public readonly state: State
  private mounted: boolean

  constructor(props: Props & WithTranslation & WithAuth0Props) {
    super(props)
    const hideCarrierSelectModal =
      props.location.state && !!props.location.state.carrierSelection
    const selectedCarrier = hideCarrierSelectModal
      ? props.location.state.carrierSelection.carrier!
      : ''
    const selectedCarrierServices = hideCarrierSelectModal
      ? props.location.state.carrierSelection.carrierServices!
      : []
    const data = getCarrierAccountData(
      this.props.caasProfiles,
      selectedCarrier,
      selectedCarrierServices
    )

    this.mounted = false
    this.state = {
      step: undefined,
      carrierAccountData: data,
      pickupCalendar: getDefaultPickupCalendar(),
      validatedFields: new Set(),
      showGetNameModal: false,
      canAddOrRemoveUpload: !data.defaultDocumentUploads,
      hideCarrierSelectModal,
      maxStepReached: 0,
    }

    if (props.existingData) {
      this.state.carrierAccountData = getExistingCarrierAccountData(
        props.existingData
      )
      this.state.canAddOrRemoveUpload =
        props.existingData.defaultDocumentUploads === undefined
      this.state.pickupCalendar = clone(
        props.existingData.incompleteCarrierAccount.createPickupCalendar
      )
      this.state.hideCarrierSelectModal = true
    }
  }

  public render() {
    const steps = this.calculateSteps(this.state.carrierAccountData)

    const onClose = () => this.setShowGetNameModal(false)
    const saveCarrierModal = this.state.showGetNameModal ? (
      <GetCarrierNameModal
        initialName={this.state.carrierAccountData.carrierAccountName}
        onClose={onClose}
        saveAccount={this.setNameAndSave}
        show={this.state.showGetNameModal}
        existingCarrierAccountNames={this.props.existingCarrierAccountNames}
      />
    ) : undefined

    const cancel = () => {
      if (this.state.carrierAccountData.carrier) {
        this.setState({ hideCarrierSelectModal: true })

        return
      }
      this.props.history.push('../carrier-accounts')
    }

    const onSelect = (selection: CarrierSelection) => {
      this.setState(prev => {
        const previousCarrier = prev.carrierAccountData.carrier
        const sameCarrier = previousCarrier === selection.carrier
        const data = getCarrierAccountData(
          this.props.caasProfiles,
          selection.carrier,
          selection.carrierServices
        )
        const hasDefaultUploads = data.defaultDocumentUploads

        const updatedSteps = this.calculateSteps(data)

        return {
          carrierAccountData: sameCarrier
            ? {
                ...prev.carrierAccountData,
                carrierServices: selection.carrierServices,
              }
            : data,
          validatedFields: new Set(),
          canAddOrRemoveUpload: !hasDefaultUploads,
          hideCarrierSelectModal: true,
          step: sameCarrier ? prev.step : getInitialStep(updatedSteps),
          maxStepReached: sameCarrier ? prev.maxStepReached : 0,
        }
      })
    }

    const carrierServices = this.state.carrierAccountData.profile
      ? (this.state.carrierAccountData.carrierServices || []).map(
          key =>
            this.state.carrierAccountData.profile!.carrierServices.find(
              cs => cs.key === key
            )!.name
        )
      : []

    const onClickEdit = () => {
      this.setState({ hideCarrierSelectModal: false })
    }

    const setMaxStepReached = (maxStepReached: number) => {
      if (maxStepReached !== this.state.maxStepReached) {
        this.setState({ maxStepReached })
      }
    }

    return (
      <div className="container">
        <CarrierSelectModalContainer
          locationId={this.props.logisticsLocation.id}
          carrierAccountData={this.state.carrierAccountData}
          onClose={cancel}
          onSelect={onSelect}
          show={!this.state.hideCarrierSelectModal}
          caasProfiles={this.props.caasProfiles}
        />
        {saveCarrierModal}
        <div className="row">
          <div className="col-md-3 col-lg-3">
            <h5>Configure Carrier</h5>
            <CarrierAccountCard
              carrierAccountKey={this.state.carrierAccountData.carrier || ''}
              carrierAccountName={
                this.state.carrierAccountData.carrierAccountName
              }
              carrierServices={carrierServices}
              onClickEdit={onClickEdit}
            />
            <CarrierAccountStepper
              stepChanged={this.onStepChanged}
              current={this.state.step || getInitialStep(steps)}
              maxStepReached={this.state.maxStepReached}
              setMaxStepReached={setMaxStepReached}
              steps={steps}
            />
            <div style={{ marginTop: '32px' }}>
              {this.props.t('carrierAccounts.addCarrierFlow.questions')} <br />
              <a href="mailto:logisticssupport@cimpress.com">
                e-mail logisticssupport@cimpress.com
              </a>
            </div>
          </div>
          <div className="col-md-9 col-lg-9">
            {this.getStepContainer(steps)}
          </div>
        </div>
      </div>
    )
  }

  public onStepChanged = (step: Steps) => {
    this.setState({ step })
  }

  public componentWillUnmount() {
    this.mounted = false
  }

  public componentDidMount() {
    this.mounted = true
  }

  private calculateSteps(carrierAccountData: CarrierAccountData) {
    const allSteps = [
      {
        type: 'group',
        key: 'configureAccount',
        contents: this.props.t('common.configureAccount'),
        steps: [
          {
            type: 'step',
            key: 'accountDetails',
            contents: this.props.t('common.accountDetails'),
            show: showCarrierAccountStep(
              carrierAccountData.accountDetailsSpecs
            ),
          },
          {
            type: 'step',
            key: 'documents',
            contents: this.props.t('carrierAccounts.addCarrierFlow.documents'),
            show: showDocumentsStep(carrierAccountData),
          },
          {
            type: 'step',
            key: 'trackingIds',
            contents: this.props.t(
              'carrierAccounts.addCarrierFlow.trackingIds'
            ),
            show: showTrackingIdsStep(carrierAccountData.sequences),
          },
        ],
      },
      {
        type: 'step',
        key: 'pickups',
        contents: this.props.t('carrierAccounts.addCarrierFlow.definePickups'),
        show: true,
      },
      {
        type: 'step',
        key: 'summary',
        contents: this.props.t('common.summary'),
        show: true,
      },
    ] as StepDefinition[]

    return skipSteps(allSteps).map((step, index) => addIndices(step, index))
  }

  private onScheduleChange = (
    schedule: calendars.models.PickupWeeklySchedule
  ) => {
    this.setState((prevState, _) => {
      const prevSchedules = prevState.pickupCalendar.weeklySchedules
      const lastSchedule = prevSchedules[prevSchedules.length - 1]
      if (lastSchedule.validFrom !== schedule.validFrom) {
        // new schedule has to be appended at the end of existing schedules
        return {
          pickupCalendar: {
            ...prevState.pickupCalendar,
            weeklySchedules: [...prevSchedules, schedule],
          },
        }
      } else {
        // last existing schedule can be changed
        return {
          pickupCalendar: {
            ...prevState.pickupCalendar,
            weeklySchedules: [...prevSchedules.slice(0, -1), schedule],
          },
        }
      }
    })
  }

  private getStepContainer(steps: StepDefinition[]) {
    const currentStep = this.state.step || getInitialStep(steps)

    const profile = this.state.carrierAccountData.profile!
    const { previous, next } = getSurroundingSteps(steps, currentStep)

    switch (currentStep) {
      case 'accountDetails':
        return (
          <CarrierAccountStep
            carrierAccountData={this.state.carrierAccountData}
            onCarrierAccountChange={this.onCarrierAccountChange}
            saveIncompleteAccount={this.saveIncompleteAccount}
            onStepChanged={this.onStepChanged}
            nextStep={next}
          />
        )
      case 'documents': {
        return (
          <DocumentsStep
            carrierAccountData={this.state.carrierAccountData}
            canAddOrRemoveUpload={this.state.canAddOrRemoveUpload}
            onUploadsChange={this.onUploadsChange}
            onSequenceDefinitionChange={this.onSequenceDefinitionChange}
            saveIncompleteAccount={this.saveIncompleteAccount}
            onStepChanged={this.onStepChanged}
            previousStep={previous}
            nextStep={next}
          />
        )
      }
      case 'trackingIds':
        return (
          <TrackingIdsStep
            carrierAccountData={this.state.carrierAccountData}
            profile={profile}
            onSequenceDefinitionChange={this.onSequenceDefinitionChange}
            saveIncompleteAccount={this.saveIncompleteAccount}
            onStepChanged={this.onStepChanged}
            previousStep={previous}
          />
        )

      case 'pickups':
        return (
          <PickupsStep
            pickupCalendar={this.state.pickupCalendar}
            onScheduleChange={this.onScheduleChange}
            saveIncompleteAccount={this.saveIncompleteAccount}
            onStepChanged={this.onStepChanged}
            previousStep={previous}
          />
        )
      case 'summary':
        return (
          <SummaryStep
            carrierAccountData={this.state.carrierAccountData}
            uploads={this.state.carrierAccountData.uploads}
            pickupCalendar={this.state.pickupCalendar}
            saveIncompleteAccount={this.saveIncompleteAccount}
            saveCompleteAccount={this.saveCompleteAccount}
            profile={profile}
            onStepChanged={this.onStepChanged}
          />
        )
    }

    throw new Error('Unknown step!')
  }

  private setShowGetNameModal = (show: boolean) => {
    if (this.mounted) {
      this.setState({ showGetNameModal: show })
    }
  }

  private onCarrierAccountChange = (accountDetails: any) => {
    this.setState(state => ({
      carrierAccountData: {
        ...state.carrierAccountData,
        accountDetails,
      },
    }))
  }

  private onUploadsChange = (uploads: Uploads[]) => {
    this.setState(state => ({
      carrierAccountData: {
        ...state.carrierAccountData,
        uploads,
      },
    }))
  }

  private onSequenceDefinitionChange = (
    sequenceDefinitions: SequenceDefinition[]
  ) => {
    this.setState({
      carrierAccountData: {
        ...this.state.carrierAccountData,
        sequenceDefinitions,
      },
    })
  }

  private saveCompleteAccount = async () => {
    this.setShowGetNameModal(true)
  }

  private setNameAndSave = async (name: string) => {
    try {
      const accessToken = await this.props.auth0.getAccessTokenSilently()

      const carrierAccountId = await this.saveCompleteOnServer(
        accessToken,
        this.props.auth0.user,
        name
      )

      SnackbarController.show(
        this.props.t('carrierAccounts.addCarrierFlow.carrierAccountAdded', {
          name,
        }),
        'success'
      )

      this.props.history.push(
        `/location/${this.props.logisticsLocation.id}/carrier-accounts/${carrierAccountId}`
      )
    } catch (err) {
      SnackbarController.show(
        this.props.t('carrierAccounts.addCarrierFlow.carrierAccountAddedFail', {
          name,
        }),
        'danger'
      )
      logError(this.props.auth0.user, 'Carrier Account save failed', err)
    }
  }

  private saveIncompleteAccount = async () => {
    try {
      await this.saveIncompleteOnServer()

      SnackbarController.show(
        this.props.t('carrierAccounts.addCarrierFlow.savedForLater', {
          name: this.state.carrierAccountData.carrierAccountName,
        }),
        'success'
      )

      this.props.history.push(
        `/location/${this.props.logisticsLocation.id}/carrier-accounts`
      )
    } catch (err) {
      SnackbarController.show(
        this.props.t('carrierAccounts.addCarrierFlow.failedSavedForLater', {
          name: this.state.carrierAccountData.carrierAccountName,
        }),
        'danger'
      )
      logError(
        this.props.auth0.user,
        'Carrier Account save for later failed',
        err
      )
    }
  }

  private async saveIncompleteOnServer() {
    const newIncompleteCarrierAccount: IncompleteCarrierAccount = {
      createCarrierAccount: {
        name: this.state.carrierAccountData.carrierAccountName,
        carrierKey: this.state.carrierAccountData.carrier!,
        carrierServiceKeys: this.state.carrierAccountData.carrierServices!,
        autoManifest: true,
        accountDetails: this.state.carrierAccountData.accountDetails,
        uploads: this.state.carrierAccountData.uploads,
      },
      sequences: this.state.carrierAccountData.sequenceDefinitions,
      createPickupCalendar: {
        ...this.state.pickupCalendar,
        name: this.state.carrierAccountData.carrierAccountName,
        workingDaysCalendar: this.props.logisticsLocation.workingDaysCalendar,
        timezone: this.props.logisticsLocation.localeSettings.timezone,
      },
    }

    const accessToken = await this.props.auth0.getAccessTokenSilently()
    if (!this.props.existingData) {
      return createIncompleteCarrierAccount(
        accessToken,
        this.props.auth0.user,
        this.props.logisticsLocation.id,
        newIncompleteCarrierAccount
      )
    } else {
      return updateIncompleteCarrierAccount(
        accessToken,
        this.props.logisticsLocation.id,
        this.props.existingData.incompleteCarrierAccount,
        {
          ...newIncompleteCarrierAccount,
          id: this.props.existingData.incompleteCarrierAccount.id,
          etag: this.props.existingData.incompleteCarrierAccount.etag,
          locationId: this.props.existingData.incompleteCarrierAccount
            .locationId,
        }
      )
    }
  }

  private async saveCompleteOnServer(
    accessToken: string,
    user: User | undefined,
    name: string
  ): Promise<string> {
    const sharedSequences = this.state.carrierAccountData.sharedSequences || {}
    const sequences: carrierAccounts.models.Sequences = clone(sharedSequences)

    const sequenceDefinitions =
      this.state.carrierAccountData!.sequenceDefinitions || []

    const createdSequencesIds = await Promise.all(
      sequenceDefinitions
        .filter(sd => !sd._metadata.isShared)
        .map(async sequenceDefinition => {
          const sequenceId = await createSequenceForLocation(
            accessToken,
            user,
            {
              ...sequenceDefinition,
              description: `For Carrier Account "${name}" key "${sequenceDefinition._metadata.sequenceKey}"`,
            },
            this.props.logisticsLocation.id
          )

          sequences[sequenceDefinition._metadata.sequenceKey] = {
            id: sequenceId,
          }

          return sequenceId
        })
    )

    const uploadsWithoutPropertyHidden = this.state.carrierAccountData.uploads.map(
      upload => {
        const { isHidden, ...allExceptHidden } = upload

        return allExceptHidden
      }
    )

    const carrierAccount: carrierAccounts.models.CreateCarrierAccount = {
      name,
      carrierKey: this.state.carrierAccountData.carrier!,
      carrierServiceKeys: this.state.carrierAccountData.carrierServices!,
      autoManifest: true,
      accountDetails: this.state.carrierAccountData.accountDetails,
      uploads: uploadsWithoutPropertyHidden,
      sequences,
    }

    try {
      const result = await this.createOnServer(accessToken, carrierAccount)

      await Promise.all(
        Object.entries(sharedSequences).map(([key, { id }]) =>
          addSequenceToLocationCoamGroup(
            accessToken,
            id,
            this.props.logisticsLocation.id
          )
        )
      )

      if (
        this.props.existingData &&
        this.props.existingData.incompleteCarrierAccount.id
      ) {
        await deleteIncompleteCarrierAccount(
          await this.props.auth0.getAccessTokenSilently(),
          this.props.logisticsLocation.id,
          this.props.existingData.incompleteCarrierAccount.id
        )
      }

      return result
    } catch (err) {
      await Promise.all(
        createdSequencesIds.map(async sequenceId => {
          try {
            await deleteSequenceFromLocation(
              accessToken,
              user,
              sequenceId,
              this.props.logisticsLocation.id
            )
          } catch (err2) {
            logError(
              this.props.auth0.user,
              `Failed to delete sequence ${sequenceId} when saving carrier account failed.`
            )
          }
        })
      )

      throw err
    }
  }

  private createOnServer(
    accessToken: string,
    carrierAccount: carrierAccounts.models.CreateCarrierAccount
  ): Promise<string> {
    const updatedPickupCalendar = {
      ...this.state.pickupCalendar,
      name: carrierAccount.name,
      workingDaysCalendar: this.props.logisticsLocation.workingDaysCalendar,
      timezone: this.props.logisticsLocation.localeSettings.timezone,
      owner: {
        logisticsLocationId: this.props.logisticsLocation.id,
      },
    }

    return createCarrierAccountForLocation(
      accessToken,
      this.props.logisticsLocation,
      updatedPickupCalendar,
      carrierAccount
    )
  }
}

export default withAuth0(withTranslation()(AddCarrierAccountPageContainer))
