import { useAuth0 } from '@auth0/auth0-react'
import * as coam from '@cimpress-technology/coam-sapidus'
import { SelectWrapper } from '@cimpress/react-components'
import styles from '@cimpress/react-components/lib/Select/styles'
import debounce from 'awesome-debounce-promise'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'
import { useIsMounted } from '../../common/hooks/useIsMounted'
import { Disabled } from '../../common/models'
import { CanonicalCoamGroupMember, CanonicalCoamUser } from '../models'
import { UserProfile } from './UserList'

interface Props {
  existingMembers: CanonicalCoamGroupMember[]
  onSelect(principal: CanonicalCoamUser): void
}
interface Option extends Disabled {
  label: string
  value: string
  isClient: boolean
  user: CanonicalCoamUser
}

export default function UserLookup(props: Props) {
  const { getAccessTokenSilently, user } = useAuth0()
  const isMounted = useIsMounted()
  const { t } = useTranslation()

  const onChange = (data: Option) => {
    props.onSelect(data.user)
  }

  const findPrincipals = async (input: string) => {
    if (!isMounted.current) {
      return
    }
    const result = await coam.users.findCanonicalUsers(
      input,
      await getAccessTokenSilently(),
      user?.sub ?? '',
      true
    )

    const rawOptions: Option[] = result.map(u => ({
      label: u.canonical_principal,
      value: u.canonical_principal,
      isClient: u.is_client,
      isDisabled: props.existingMembers.some(member => {
        return member.principal === u.canonical_principal
      }),
      user: u,
    }))

    const [alreadyAMember, areClients] = rawOptions.reduce(
      (accum, option) => {
        if (option.isDisabled) {
          accum[0].push(option)
        }
        if (option.isClient && !option.isDisabled) {
          accum[1].push(option)
        }

        return accum
      },
      [[] as Option[], [] as Option[]]
    )

    return alreadyAMember.length === 0 && areClients.length === 0
      ? rawOptions
      : [
          {
            label: t('userManagement.matchingUsers'),
            options: rawOptions.filter(
              option => !option.isDisabled && !option.isClient
            ),
          },
          {
            label: t('userManagement.matchingClients'),
            options: areClients,
          },
          {
            label: t('userManagement.alreadyAMember'),
            options: alreadyAMember,
          },
        ]
  }

  const debouncedFindPrincipals = debounce(findPrincipals, 300)

  const noOptionsMessage = (obj: { inputValue: string }) => {
    return ''
  }

  return (
    <SelectWrapper
      styles={styles}
      selectedSelect={AsyncSelect}
      loadOptions={debouncedFindPrincipals}
      cacheOptions={false}
      defaultOptions={false}
      isClearable={true}
      label={t('userManagement.searchForUser')}
      noOptionsMessage={noOptionsMessage}
      arrowRenderer={arrowRenderer}
      formatOptionLabel={formatOptionLabel}
      onChange={onChange}
      placeholder=""
    />
  )
}

function formatOptionLabel(option: Option, labelMeta: any) {
  return <UserProfile user={option.user} clickable={true} />
}

const arrowRenderer = () => <></>
