import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import AwaitingModal from '../../calendars/components/AwaitingModal'
import { useNetwork } from '../NetworkContext'

interface Props {
  onClose: () => any
  logisticsLocationId: string
}

export default function RemoveLocationFromNetworkModal(props: Props) {
  const { t } = useTranslation()
  const { removeLocationFromNetwork } = useNetwork()
  const { network } = useNetwork()
  const [loading, setLoading] = React.useState(false)

  const onSave = async () => {
    try {
      await removeLocationFromNetwork(
        props.logisticsLocationId,
        network.apiNetwork.id
      )
      setLoading(false)
      SnackbarController.show(
        'Location successfully removed from network',
        'success'
      )

      props.onClose()
    } catch (error) {
      SnackbarController.show('Error removing location from network', 'danger')
      setLoading(false)
      props.onClose()
    }
  }

  const footer = loading
    ? []
    : [
        {
          handler: props.onClose,
          text: t('common.cancel'),
        },
        {
          type: 'primary',
          handler: onSave,
          text: 'Remove from network',
          savingText: t('common.saving'),
        },
      ]

  const content = loading ? <Preloader /> : null

  return (
    <AwaitingModal
      show={true}
      onRequestHide={props.onClose}
      closeButton={true}
      closeOnOutsideClick={false}
      title={`Remove location ${props.logisticsLocationId} from ${network.apiNetwork.name}`}
      style={{ width: '526px' }}
      footer={footer}
    >
      {content}
    </AwaitingModal>
  )
}
