import { useAuth0 } from '@auth0/auth0-react'
import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as reactComponents from '@cimpress/react-components'
import * as ReactDOM from 'react-dom'
import * as reactRouter from 'react-router'
import * as reactRouterDom from 'react-router-dom'
import * as React from 'react'
import * as models from '../../common/models'
import { useLogisticsLocation } from '../../locations/LocationContext'
import { Profile } from '../auth'
import { config } from '../config/config'
import { useNetwork } from '../../networks/NetworkContext'
import { useUserContext } from '../UserContext'

export interface FragmentProps {
  src: string
  fallback: JSX.Element
  error?: (error: Error) => JSX.Element
}

type FragmentComponentType = 'location' | 'network' | 'locationInNetwork'

interface FragmentComponentCommonProps {
  environment: string
  token: string
  user: Profile
  type: FragmentComponentType
  isAdvancedUser: boolean
}
type FragmentComponentProps = FragmentComponentCommonProps &
  (
    | FragmentComponentLocationProps
    | FragmentComponentNetworkProps
    | FragmentComponentLocationInNetworkProps
  )

interface FragmentComponentLocationProps {
  type: 'location'
  location?: models.Location
  reloadLocation: () => Promise<void>
  updateLocation?: models.LocationMutator
  linkResourceToLocation?: (
    accessToken: string,
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>
}

interface FragmentComponentLocationInNetworkProps {
  type: 'locationInNetwork'
  network?: locations.models.LogisticsNetworkWithLinks
  updateNetwork?: models.NetworkMutator
  linkResourceToNetwork?: (
    accessToken: string,
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>
}

interface FragmentComponentNetworkProps {
  type: 'network'
  network?: locations.models.LogisticsNetworkWithLinks
  updateNetwork?: models.NetworkMutator
  linkResourceToNetwork?: (
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>
  locations?: locations.models.LocationWithLinks[]
}

export default function Fragment(props: FragmentProps) {
  const { getAccessTokenSilently, user } = useAuth0<Profile>()
  const [token, setToken] = React.useState<string>('')
  const {
    logisticsLocation,
    updateLocation,
    network,
    updateNetwork,
    linkResourceToLocation,
    linkResourceToNetwork,
  } = useLogisticsLocation()
  const networkContext = useNetwork()
  const { isAdvancedUser } = useUserContext()

  const type = getType(logisticsLocation)

  const reloadLocation = async () => {
    await updateLocation(() => undefined)
  }
  const [
    Component,
    setComponent,
  ] = React.useState<null | React.LazyExoticComponent<
    React.ComponentType<FragmentComponentProps>
  >>(null)

  const { src, error } = props

  React.useEffect(() => {
    getAccessTokenSilently().then(setToken)
  }, [getAccessTokenSilently])

  React.useEffect(() => {
    if (src && src.length) {
      const promise = () =>
        import(/* webpackIgnore: true */ src)
          .then(module => ({
            default: module.default({
              React,
              ReactDOM,
              reactComponents,
              reactRouter,
              reactRouterDom,
            }),
          }))
          .catch(e => {
            return {
              default: error ? error(e) : () => <div />,
            }
          })

      setComponent(React.lazy(promise))
    }
  }, [src, error])

  if (!Component || !token) {
    return null
  }

  if (type === 'network') {
    return (
      <React.Suspense fallback={props.fallback}>
        <Component
          isAdvancedUser={isAdvancedUser}
          environment={config.logisticsEnvironment}
          token={token}
          user={user!}
          reloadLocation={reloadLocation}
          type={type}
          network={networkContext.network.apiNetwork}
          updateNetwork={
            networkContext.network.editable
              ? networkContext.updateNetwork
              : undefined
          }
          linkResourceToNetwork={
            networkContext.linkResourceToNetwork
              ? networkContext.linkResourceToNetwork
              : undefined
          }
          locations={networkContext.locations}
        />
      </React.Suspense>
    )
  }

  return (
    <React.Suspense fallback={props.fallback}>
      <Component
        isAdvancedUser={isAdvancedUser}
        type={type}
        environment={config.logisticsEnvironment}
        token={token}
        user={user!}
        location={logisticsLocation}
        reloadLocation={reloadLocation}
        updateLocation={updateLocation}
        linkResourceToLocation={linkResourceToLocation}
        network={network?.apiNetwork}
        updateNetwork={network?.editable ? updateNetwork : undefined}
        linkResourceToNetwork={
          network?.editable ? linkResourceToNetwork : undefined
        }
      />
    </React.Suspense>
  )
}

function getType(location: models.Location): FragmentComponentType {
  if (location) {
    return location.networkIsAccessible ? 'locationInNetwork' : 'location'
  }

  return 'network'
}
