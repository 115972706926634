import axios from 'axios'
import { FulfillmentLocation } from '../models'

const FL_URL =
  'https://fulfillmentlocation.trdlnk.cimpress.io/v1/fulfillmentlocations'

export const getFLs = async (
  authorization: string,
  disableCaching?: boolean
): Promise<FulfillmentLocation[]> => {
  const flResponse = (
    await axios.get(FL_URL, {
      params: {
        showArchived: true,
      },
      headers: {
        authorization: `Bearer ${authorization}`,
        'Cache-Control': 'no-cache',
        'x-cache-id': disableCaching ? Date.now() : undefined,
      },
    })
  ).data

  return flResponse.map((l: any) => {
    return {
      id: l.fulfillmentLocationId,
      name: l.name,
      address: {
        locality: l.dispatchAddressCity,
        countryCode: l.dispatchAddressCountry,
        postalCode: l.dispatchAddressPostalCode,
        region: l.dispatchAddressRegion,
        street1: l.dispatchAddressStreet1,
        street2: l.dispatchAddressStreet2,
      },
      timezone: l.timeZone,
      fulfiller: { fulfillerId: l.fulfillerId },
      isArchived: l.archived,
    }
  })
}
