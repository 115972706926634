import * as coam from '@cimpress-technology/coam-sapidus'
import {
  ApiFulfillmentLocation,
  Fulfiller,
  FulfillmentLocation,
} from '../models'
import { getFulfillers } from './fulfillers-proxy'
import { getFLs } from './fulfillment-locations-proxy'

let cache: Promise<FulfillmentLocation[]> | undefined

async function getAllFls(
  accessToken: string,
  sub: string,
  disableCaching?: boolean
) {
  const [locations, allowedToEdit, fulfillers] = await Promise.all([
    getFLs(accessToken, disableCaching),
    coam.auth.getAllowedIds(
      coam.models.ResourceTypes.FulfillmentLocations,
      sub,
      coam.models.FLPermissions.ManageLogisticsConfigurationData,
      `Bearer ${accessToken}`,
      sub
    ),
    getFulfillers(accessToken, disableCaching),
  ])

  locations.forEach(location => {
    location.editable =
      allowedToEdit === 'all' || allowedToEdit.indexOf(location.id) !== -1
    location.fulfiller = getFulfiller(
      fulfillers,
      location.fulfiller.fulfillerId
    )
  })

  return locations
}
export function getAll(
  accessToken: string,
  sub: string,
  disableCaching?: boolean
): Promise<FulfillmentLocation[]> {
  if (disableCaching) {
    cache = undefined
  }

  if (cache) {
    return cache
  }

  cache = getAllFls(accessToken, sub, disableCaching)

  return cache
}

function getFulfiller(fulfillers: Map<string, Fulfiller>, fulfillerId: string) {
  return (
    fulfillers.get(fulfillerId) || {
      fulfillerId,
      name: `Fulfiller ${fulfillerId}`,
    }
  )
}
export function apiFLsToFLs(
  locationFls: ApiFulfillmentLocation[],
  allFLs: FulfillmentLocation[]
): FulfillmentLocation[] {
  return locationFls.map(apiFl => {
    const fl = allFLs.find(allFL => allFL.id === apiFl.id)
    if (fl) {
      return {
        id: fl.id,
        name: fl.name,
        editable: fl.editable,
        fulfiller: fl.fulfiller,
        isArchived: fl.isArchived ?? false,
      }
    }

    return {
      id: apiFl.id,
      name: `Archived (${apiFl.id})`,
      editable: false,
      fulfiller: { fulfillerId: '', name: 'Archived Fulfiller' },
      isArchived: true,
    }
  })
}

export async function getEditable(
  accessToken: string,
  sub: string,
  disableCaching?: boolean
): Promise<FulfillmentLocation[]> {
  return (await getAll(accessToken, sub, disableCaching)).filter(
    fl => fl.editable
  )
}
