import { useAuth0 } from '@auth0/auth0-react'
import SettingsModal from '@cimpress-technology/react-platform-settings'
import { getSettings } from '@cimpress-technology/react-platform-settings/lib/SettingsClient'
import * as React from 'react'
import i18n, { supportedLanguages } from '../../i18n'

interface Props {
  selectedLanguage: string
}

export function Preferences(props: Props) {
  const { getAccessTokenSilently } = useAuth0()

  const [token, setToken] = React.useState<string>('')

  React.useEffect(() => {
    getAccessTokenSilently().then(setToken)
  }, [getAccessTokenSilently])

  const onSave = async () => {
    const accessToken = await getAccessTokenSilently()
    const settings = await getSettings(accessToken, supportedLanguages)
    i18n.changeLanguage(settings.global.language)
  }

  if (!token) {
    return null
  }

  return (
    <SettingsModal
      authToken={token}
      lang={props.selectedLanguage}
      supportedLanguages={supportedLanguages}
      onSave={onSave}
    />
  )
}
