import { User } from '@auth0/auth0-react'
import axios from 'axios'
import { config } from '../config/config'
import { logError } from '../logger'

export async function getQcmLink(
  accessToken: string,
  user: User | undefined,
  logisticLocationId: string,
  fulfillmentLocationId: string
): Promise<string | undefined> {
  try {
    await checkIfQcmConfigurationExists(accessToken, fulfillmentLocationId)

    return `${config.qcm}/ui/fulfillmentLocations/${logisticLocationId}`
  } catch (e) {
    if (e.response && e.response.status === 404) {
      return undefined
    }
    logError(user, 'Unable to retrieve QCM status')
    throw e
  }
}

async function checkIfQcmConfigurationExists(
  accessToken: string,
  fulfillmentLocationId: string
) {
  await axios.get(
    `${config.shippingCalculator}/api/v2/fulfillmentLocations/${fulfillmentLocationId}/latestConfigurations`,
    { headers: { authorization: accessToken } }
  )
}
