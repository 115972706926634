import { User } from '@auth0/auth0-react'
import * as coam from '@cimpress-technology/coam-sapidus'
import {
  calendars,
  carrierAccounts,
  locations,
} from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import * as _ from 'underscore'
import * as uuid from 'uuid'
import { createPickupCalendar, deletePickupCalendar } from './calendars-store'
import { updateLocation } from './locations-store'
import { deleteSequenceFromLocation } from './sequences-store'

export function createCarrierAccount(
  accessToken: string,
  carrierAccount: carrierAccounts.models.CreateCarrierAccount
): Promise<string> {
  return carrierAccounts.createCarrierAccount(
    accessToken,
    uuid.v4(),
    carrierAccount
  )
}

export function deleteCarrierAccount(
  accessToken: string,
  carrierAccountId: string
) {
  return carrierAccounts.deleteCarrierAccount(
    accessToken,
    uuid.v4(),
    carrierAccountId
  )
}

export async function getCarrierAccountsForLocation(
  accessToken: string,
  location: locations.models.Location
): Promise<Array<carrierAccounts.models.CarrierAccountWithLink | undefined>> {
  const correlationId = uuid.v4()

  try {
    return await Promise.all(
      location.carrierAccounts.map(ca =>
        carrierAccounts.getCarrierAccount(accessToken, correlationId, ca.id)
      )
    )
  } catch (err) {
    return []
  }
}

export function updateCarrierAccount(
  accessToken: string,
  oldCarrierAccount: carrierAccounts.models.CarrierAccount,
  newCarrierAccount: carrierAccounts.models.CarrierAccount
): Promise<string> {
  return carrierAccounts.updateCarrierAccount(
    accessToken,
    uuid.v4(),
    oldCarrierAccount.id,
    oldCarrierAccount.etag,
    jsonPatch.compare(oldCarrierAccount, newCarrierAccount)
  )
}

export function getCarrierAccount(
  accessToken: string,
  id: string
): Promise<carrierAccounts.models.CarrierAccountWithLink | undefined> {
  return carrierAccounts.getCarrierAccount(accessToken, uuid.v4(), id)
}

export async function createCarrierAccountForLocation(
  accessToken: string,
  location: locations.models.Location,
  pickupCalendar: calendars.models.CreatePickupCalendar,
  carrierAccount: carrierAccounts.models.CreateCarrierAccount
): Promise<string> {
  const correlationId = uuid.v4()
  const carrierAccountId = await createCarrierAccount(
    accessToken,
    carrierAccount
  )

  try {
    const pickupCalendarId = await createPickupCalendar(
      accessToken,
      pickupCalendar,
      correlationId
    )

    try {
      await linkResourcesToLocation(
        location,
        pickupCalendarId,
        carrierAccountId,
        accessToken
      )

      await updateLocation(
        accessToken,
        location.id,
        location.etag,
        [
          {
            op: 'add',
            path: '/carrierAccounts/-',
            value: { id: carrierAccountId },
          },
        ],
        correlationId
      )

      return carrierAccountId
    } catch (e) {
      await deletePickupCalendar(accessToken, pickupCalendarId, correlationId)
      throw e
    }
  } catch (e) {
    await deleteCarrierAccount(accessToken, carrierAccountId)
    throw e
  }
}

async function linkResourcesToLocation(
  location: locations.models.Location,
  pickupCalendarId: string,
  carrierAccountId: string,
  accessToken: string
) {
  const correlationId = uuid.v4()
  try {
    await Promise.all([
      locations.linkResourceToLocationGroup(
        accessToken,
        correlationId,
        location.id,
        pickupCalendarId,
        coam.models.ResourceTypes.Calendar
      ),
      locations.linkResourceToLocationGroup(
        accessToken,
        correlationId,
        location.id,
        carrierAccountId,
        coam.models.ResourceTypes.CarrierAccount
      ),
    ])
  } catch (e) {
    await Promise.all([
      locations.unlinkResourceFromLocationGroup(
        accessToken,
        correlationId,
        location.id,
        pickupCalendarId,
        coam.models.ResourceTypes.Calendar
      ),
      locations.unlinkResourceFromLocationGroup(
        accessToken,
        correlationId,
        location.id,
        carrierAccountId,
        coam.models.ResourceTypes.CarrierAccount
      ),
    ])
    throw e
  }
}

export async function deleteCarrierAccountForLocation(
  accessToken: string,
  user: User | undefined,
  location: locations.models.Location,
  carrierAccountId: string
) {
  const correlationId = uuid.v4()

  const carrierConfigurationIndex = location.carrierAccounts.findIndex(
    ca => ca.id === carrierAccountId
  )

  await updateLocation(accessToken, location.id, location.etag, [
    {
      op: 'remove',
      path: '/carrierAccounts/' + carrierConfigurationIndex,
    },
  ])

  await locations.unlinkResourceFromLocationGroup(
    accessToken,
    correlationId,
    location.id,
    carrierAccountId,
    coam.models.ResourceTypes.CarrierAccount
  )

  const carrierAccount = await getCarrierAccount(accessToken, carrierAccountId)
  if (carrierAccount) {
    const sequenceIds = _.map(carrierAccount.sequences || {}, s => s.id)

    await Promise.all<Promise<void>>(
      sequenceIds.map(sequenceId =>
        deleteSequenceFromLocation(accessToken, user, sequenceId, location.id)
      )
    )
  }

  await deleteCarrierAccount(accessToken, carrierAccountId)
}
