import * as coam from '@cimpress-technology/coam-sapidus'
import * as React from 'react'
import * as models from '../common/models'

export interface LocationContext {
  logisticsLocation: models.Location
  updateLocation: models.LocationMutator
  loading: boolean
  isNetworkAdmin: boolean
  reports: models.Report[]
  linkResourceToLocation: (
    accessToken: string,
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>

  network?: models.Network
  updateNetwork?: models.NetworkMutator
  linkResourceToNetwork?: (
    accessToken: string,
    resourceId: string,
    resourceType: coam.models.ResourceTypes
  ) => Promise<void>
}

export const Context = React.createContext<LocationContext>(
  {} as LocationContext
)

export const Provider = Context.Provider

export function useLogisticsLocation() {
  return React.useContext(Context)
}
