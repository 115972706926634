import { withAuth0, WithAuth0Props } from '@auth0/auth0-react'
import { Button, Select, TextField } from '@cimpress/react-components'
import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import { Link, RouteComponentProps } from 'react-router-dom'
import { WithTranslation, withTranslation } from 'react-i18next'
import * as React from 'react'
import { PickupCalendar } from '../../calendars/models'
import { SnackbarController } from '../../common/components/SnackbarController'
import { ValidationContainer } from '../../common/components/ValidationContainer'
import { clone } from '../../common/helpers/clone'
import {
  ValidationResult,
  ValidationStatus,
} from '../../common/helpers/validation'
import { logError } from '../../common/logger'
import { Location, Option, Uploads } from '../../common/models'
import { updatePickupCalendar } from '../../common/proxy/calendars-store'
import { updateCarrierAccount } from '../../common/proxy/carrier-accounts-store'
import { validateCarrierAccountName } from '../carrierAccountValidation'
import { CarrierConfigurationContainer } from './spec-components/CarrierConfigurationContainer'

export interface Props
  extends RouteComponentProps<{
    locationId: string
    carrierAccountId: string
  }> {
  logisticsLocation: Location
  carrierAccount: carrierAccounts.models.CarrierAccountWithLink
  configSpecs: any
  existingCarrierAccountNames: string[]
  pickupCalendar: PickupCalendar
  documentTypeKeys: Option[]
  carrierServices: Option[]
}

interface State {
  accountDetails: any
  carrierAccountName: string
  selectedCarrierServices: string[]
  uploads: Uploads[]

  selectedUploadOption: carrierAccounts.models.UploadType
  validationResults: ValidationResult[]
}

class CarrierAccountEditor extends React.Component<
  Props & WithTranslation & WithAuth0Props,
  State
> {
  public readonly state = {
    accountDetails: clone(this.props.carrierAccount.accountDetails),
    carrierAccountName: this.props.carrierAccount.name,
    uploads: clone(this.props.carrierAccount.uploads),
    selectedUploadOption: 'email' as carrierAccounts.models.UploadType,
    validationResults: [],
    selectedCarrierServices: clone(
      this.props.carrierAccount.carrierServiceKeys
    ),
  }

  public render() {
    return (
      <div className="container">
        <div className="row" style={{ marginBottom: '32px' }}>
          <div
            className="col-md-9 col-lg-9 align-self-center"
            style={{ float: 'none', margin: 'auto' }}
          >
            <h1>
              {this.props.t('carrierAccounts.editAccount', {
                name: this.props.carrierAccount.name,
              })}
            </h1>
            <div className="card">
              <div className="card-block">
                <ValidationContainer validationResult={this.validateName()}>
                  <TextField
                    value={this.state.carrierAccountName}
                    label={this.props.t('carrierAccounts.configureAccountName')}
                    onChange={this.handleNameChange}
                    required={true}
                    bsStyle={
                      this.validateName()!.status === ValidationStatus.Invalid
                        ? 'error'
                        : undefined
                    }
                  />
                </ValidationContainer>
                <ValidationContainer
                  validationResult={this.validateCarrierServices()}
                >
                  <Select
                    label={this.props.t(
                      'carrierAccounts.selectCarrierServices'
                    )}
                    value={this.props.carrierServices.filter(o =>
                      this.state.selectedCarrierServices.includes(o.value)
                    )}
                    options={this.props.carrierServices}
                    required={true}
                    onChange={this.onCarrierServicesChange}
                    isMulti={true}
                  />
                </ValidationContainer>
                <h3>{this.props.t('common.configureAccount')}</h3>
                <CarrierConfigurationContainer
                  edit={true}
                  accountDetails={this.state.accountDetails}
                  configSpecs={this.props.configSpecs}
                  onCarrierAccountChange={this.onCarrierAccountChange}
                />
              </div>
            </div>
            <div style={{ paddingTop: '16px', paddingBottom: '32px' }}>
              <span style={{ float: 'right' }}>
                <Link
                  to={`../../carrier-accounts/${this.props.carrierAccount.id}`}
                  className="btn btn-default"
                >
                  {this.props.t('common.cancel')}
                </Link>{' '}
                <Button
                  type="primary"
                  onClick={this.save}
                  disabled={!this.isValid()}
                >
                  {this.props.t('common.saveChanges')}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private onCarrierAccountChange = (
    accountDetails: any,
    validationResults: ValidationResult[]
  ) => {
    this.setState({
      accountDetails,
      validationResults,
    })
  }

  private onCarrierServicesChange = (changeData: Option[]) => {
    this.setState({
      selectedCarrierServices: changeData.map(cs => cs.value as string),
    })
  }

  private handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      carrierAccountName: e.currentTarget.value,
    })
  }

  private validateName = (): ValidationResult => {
    return validateCarrierAccountName(
      this.state.carrierAccountName,
      this.props.existingCarrierAccountNames
    )
  }

  private validateCarrierServices = (): ValidationResult => {
    return this.state.selectedCarrierServices.length === 0
      ? {
          fieldName: 'selectedCarrierServices',
          status: ValidationStatus.Invalid,
          message: this.props.t(
            'carrierAccounts.pleaseSelectAtLeastOneCarrierService'
          ),
        }
      : {
          fieldName: 'selectedCarrierServices',
          status: ValidationStatus.Valid,
        }
  }

  private isValid = () => {
    const result =
      this.state.validationResults.length === 0 &&
      this.validateName().status === ValidationStatus.Valid &&
      this.validateCarrierServices().status === ValidationStatus.Valid

    return result
  }

  private save = async () => {
    try {
      const accessToken = await this.props.auth0.getAccessTokenSilently()
      const carrierAccount: carrierAccounts.models.CarrierAccount = {
        ...this.props.carrierAccount,
        accountDetails: this.state.accountDetails,
        name: this.state.carrierAccountName,
        uploads: this.state.uploads,
        carrierServiceKeys: this.state.selectedCarrierServices,
      }

      await updateCarrierAccount(
        accessToken,
        this.props.carrierAccount,
        carrierAccount
      )

      if (this.props.carrierAccount.name !== carrierAccount.name) {
        await updatePickupCalendar(
          accessToken,
          this.props.pickupCalendar.id!,
          this.props.pickupCalendar.etag!,
          [{ op: 'replace', value: carrierAccount.name, path: '/name' }]
        )
      }

      SnackbarController.show(
        this.props.t('carrierAccounts.carrierAccountNameIsUpdated.', {
          name: carrierAccount.name,
        }),
        'success'
      )
      this.props.history.push(
        `/location/${this.props.logisticsLocation.id}/carrier-accounts/${carrierAccount.id}`
      )
    } catch (err) {
      SnackbarController.show(
        this.props.t('carrierAccounts.failedToUpdateCarrierAccountName', {
          name: this.props.carrierAccount.name,
        }),
        'danger'
      )
      logError(this.props.auth0.user, 'Carrier Account update failed', err)
    }
  }
}

export default withAuth0(withTranslation()(CarrierAccountEditor))
