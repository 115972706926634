import { useAuth0 } from '@auth0/auth0-react'
import * as React from 'react'
import { getEditable } from '../proxy/fulfillment-locations-store'
import { getAllLocations } from '../proxy/locations-store'
import * as models from '../../common/models'

export default function useFulfillmentLocations(includeArchived: boolean) {
  const { getAccessTokenSilently, user } = useAuth0()
  const [availableFLs, setAvailableFLs] = React.useState<
    models.FulfillmentLocation[]
  >()
  const [usedFLs, setUsedFLs] = React.useState<models.FulfillmentLocation[]>([])

  React.useEffect(() => {
    let isMounted = true
    const fetch = async () => {
      const accessToken = await getAccessTokenSilently()
      const sub = user?.sub ?? ''
      const [editableFLs, allLocations] = await Promise.all([
        getEditable(accessToken, sub, true),
        getAllLocations(accessToken, sub),
      ])
      if (isMounted) {
        const allUsedFLs: models.FulfillmentLocation[] = []
        Array.from(allLocations.values()).forEach(location =>
          allUsedFLs.push(...location.fulfillmentLocations)
        )
        const usedFLIds = allUsedFLs.map(fl => fl.id)
        const allAvailableFLs = editableFLs
          .filter(fl => usedFLIds.indexOf(fl.id) === -1)
          .filter(fl => includeArchived === true || !fl.isArchived)
        setAvailableFLs(allAvailableFLs)
        setUsedFLs(allUsedFLs)
      }
    }

    fetch()

    return () => {
      isMounted = false
    }
  }, [getAccessTokenSilently, includeArchived, user])

  return { availableFLs, usedFLs }
}
