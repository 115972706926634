import { useAuth0 } from '@auth0/auth0-react'
import * as coam from '@cimpress-technology/coam-sapidus'
import {
  LogisticsLocationRoles,
  LogisticsNetworkRoles,
} from '@cimpress-technology/logistics-configuration-client/js/models/locations'
import { Modal } from '@cimpress/react-components'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation, Trans } from 'react-i18next'
import * as React from 'react'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import * as locationsStore from '../../common/proxy/locations-store'
import * as networkStore from '../../common/proxy/networks-store'
import { useNetwork } from '../../networks/NetworkContext'
import { CanonicalCoamUser, networkRoles, getName } from '../models'
import PermissionsEditor from './PermissionsEditor'

type Progress = 'off' | 'in progress'

export function mapNetworkRoleToLocation(
  role: LogisticsNetworkRoles.LogisticsNetworkAdmin
): LogisticsLocationRoles.LogisticsManager
export function mapNetworkRoleToLocation(
  role: LogisticsNetworkRoles.LogisticsNetworkOperator
): LogisticsLocationRoles.LogisticsOperator
export function mapNetworkRoleToLocation(
  role: LogisticsNetworkRoles
): LogisticsLocationRoles | undefined
export function mapNetworkRoleToLocation(
  role: LogisticsNetworkRoles
): LogisticsLocationRoles | undefined {
  if (role === LogisticsNetworkRoles.LogisticsNetworkAdmin) {
    return LogisticsLocationRoles.LogisticsManager
  } else if (role === LogisticsNetworkRoles.LogisticsNetworkOperator) {
    return LogisticsLocationRoles.LogisticsOperator
  } else {
    return undefined
  }
}

export default function NetworkPermissionsEditor() {
  const { getAccessTokenSilently, user } = useAuth0()
  const { t } = useTranslation()
  const { network, locations: networkLocations } = useNetwork()
  const [progress, setProgress] = React.useState<Progress>('off')
  const [count, setCount] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [coamGroup, setCoamGroup] = React.useState<
    coam.models.DetailedCoamGroup
  >()

  const fetchData = async () => {
    setLoading(true)
    const coamGroupOverview = await coam.group.findCoamGroupByResourceAndName(
      {
        id: network.apiNetwork.id,
        type: coam.models.ResourceTypes.LogisticsNetwork,
      },
      `Logistics network (${network.apiNetwork.id})`,
      await getAccessTokenSilently(),
      user?.sub ?? ''
    )
    if (!coamGroupOverview) {
      setLoading(false)

      return
    }

    const coamGroupDetail = await coam.group.getCoamGroupDetails(
      coamGroupOverview.id,
      await getAccessTokenSilently(),
      user?.sub ?? '',
      true
    )

    setCoamGroup(coamGroupDetail)
    setLoading(false)
  }

  React.useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network.apiNetwork.id])

  if (loading) {
    return <Preloader />
  }

  const removeUser = async (email: string) => {
    const accessToken = await getAccessTokenSilently()
    await networkStore.removeUser(accessToken, network.apiNetwork.id, email)
    await fetchData()
  }

  const saveUser = async (
    coamUser: CanonicalCoamUser,
    role: LogisticsNetworkRoles,
    action: 'add' | 'edit'
  ) => {
    setProgress('in progress')
    const accessToken = await getAccessTokenSilently()
    const username = getName(coamUser)
    const email = coamUser.canonical_principal || coamUser.principal
    const locationRole = mapNetworkRoleToLocation(role)
    try {
      for (let i = 0; i < networkLocations.length; i++) {
        setCount(i + 1)
        await locationsStore.addUser(
          accessToken,
          networkLocations[i].id,
          email,
          locationRole!
        )
      }

      await networkStore.addUser(
        accessToken,
        network.apiNetwork.id,
        email,
        role
      )
      const message =
        action === 'add'
          ? t('userManagement.messages.userHasBeenAdded', {
              username,
              role,
            })
          : t('userManagement.messages.userHasBeenUpdated', {
              username,
              role,
            })

      SnackbarController.show(message, 'success')
    } catch (e) {
      const key =
        action === 'add'
          ? 'userManagement.messages.networkAddFailed'
          : 'userManagement.messages.networkUpdateFailed'
      SnackbarController.show(
        <Trans i18nKey={key}>
          Unable to add {{ username }} to all locations, please try again. If
          this problem persist, please contact
          <a
            style={{ fontSize: '14px', padding: 0, textTransform: 'none' }}
            href="mailto:logisticssupport@cimpress.com"
          >
            Logistics Support
          </a>
        </Trans>,
        'danger'
      )
    }
    await fetchData()
    setProgress('off')
  }

  return (
    <>
      <BreadcrumbsItem to={`/network/${network.apiNetwork.id}`}>
        {network.apiNetwork.name}
      </BreadcrumbsItem>
      <BreadcrumbsItem to="#">
        {t('userManagement.userManagement')}
      </BreadcrumbsItem>
      <PermissionsEditor
        editable={network.editable}
        roles={networkRoles}
        saveUser={saveUser}
        removeUser={removeUser}
        coamGroup={coamGroup}
      />
      {progress !== 'off' && (
        <ProgressModal count={count} total={networkLocations.length} />
      )}
    </>
  )
}

interface ProgressModalProps {
  count: number
  total: number
}

function ProgressModal(props: ProgressModalProps) {
  return (
    <Modal
      show={true}
      closeButton={true}
      closeOnOutsideClick={false}
      title={'Updating Network Locations'}
    >
      <Preloader />
      Updating location {props.count} of {props.total}. <br />
      Please keep this window open till this operation is finished.
    </Modal>
  )
}
