import { calendars } from '@cimpress-technology/logistics-configuration-client'
import * as jsonPatch from 'fast-json-patch'
import * as uuid from 'uuid'

export function createPickupCalendar(
  accessToken: string,
  calendar: calendars.models.CreatePickupCalendar,
  correlationId: string
): Promise<string> {
  return calendars.createPickupCalendar(accessToken, correlationId, calendar)
}

export function deletePickupCalendar(
  accessToken: string,
  calendarId: string,
  correlationId: string
): Promise<void> {
  return calendars.deletePickupCalendar(accessToken, correlationId, calendarId)
}

export function updatePickupCalendar(
  accessToken: string,
  id: string,
  etag: string,
  patch: jsonPatch.Operation[],
  correlationId?: string
): Promise<string> {
  return calendars.updatePickupCalendar(
    accessToken,
    correlationId || uuid.v4(),
    id,
    etag,
    patch
  )
}
