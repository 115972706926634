import { carrierAccounts as carrierAccountsClient } from '@cimpress-technology/logistics-configuration-client'
import { useAuth0, User } from '@auth0/auth0-react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import * as React from 'react'
import LoaderContainer from '../../common/components/LoaderContainer'
import { Location, Option, SequenceDefinition } from '../../common/models'
import {
  getCarrierAccount,
  getCarrierAccountsForLocation,
} from '../../common/proxy/carrier-accounts-store'
import {
  getCaasProfile,
  getCaasProfiles,
  getDocumentTypeKeys,
} from '../../common/proxy/carrier-services-store'
import { getSequenceDirect } from '../../common/proxy/sequences-store'
import { useLogisticsLocation } from '../../locations/LocationContext'
import AddCarrierAccountPageContainer, {
  PreloadedData,
} from './add-carrier-flow/AddCarrierConfigurationContainer'
import CarrierAccountEditor, {
  Props as CarrierAccountEditorProps,
} from './CarrierAccountEditor'
import { CarrierAccountsList } from './CarrierAccountsList'
import EditSequencesPage from './EditSequencesPage'
import { UploadsEditor } from './UploadsEditor'
import ViewCarrierAccountPageContainer from './ViewCarrierAccountPageContainer'

export function CarrierAccountsContainer() {
  const { getAccessTokenSilently, user } = useAuth0()
  const { logisticsLocation } = useLogisticsLocation()
  const match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}/add`}
        component={add(getAccessTokenSilently, logisticsLocation)}
      />
      <Route
        path={`${match.path}/:carrierAccountId/edit-account`}
        component={editAccount(getAccessTokenSilently, logisticsLocation)}
      />
      <Route
        path={`${match.path}/:carrierAccountId/edit-uploads`}
        component={editUploads(getAccessTokenSilently, user, logisticsLocation)}
      />
      <Route
        path={`${match.path}/:carrierAccountId/edit-sequences`}
        component={editSequences(
          getAccessTokenSilently,
          user,
          logisticsLocation
        )}
      />
      <Route
        path={`${match.path}/:carrierAccountId`}
        component={ViewCarrierAccountPageContainer}
      />
      <Route path={`${match.path}`}>
        <CarrierAccountsList />
      </Route>
    </Switch>
  )
}

function add(getAccessToken: () => Promise<string>, location: Location) {
  return LoaderContainer(
    AddCarrierAccountPageContainer,
    async (_): Promise<PreloadedData | undefined> => {
      const accessToken = await getAccessToken()
      const [carrierAccounts, caasProfiles] = await Promise.all([
        getCarrierAccountsForLocation(accessToken, location!),
        getCaasProfiles(accessToken),
      ])

      return {
        logisticsLocation: location,
        existingCarrierAccountNames: (carrierAccounts.filter(
          ca => ca !== undefined
        ) as carrierAccountsClient.models.CarrierAccountWithLink[]).map(
          ca => ca.name
        ),
        caasProfiles,
      }
    }
  )
}

function editAccount(
  getAccessToken: () => Promise<string>,
  location: Location
) {
  return LoaderContainer(
    CarrierAccountEditor,
    async (props: CarrierAccountEditorProps) => {
      const accessToken = await getAccessToken()
      const [carrierAccount, carrierAccounts] = await Promise.all([
        getCarrierAccount(accessToken, props.match.params.carrierAccountId),
        getCarrierAccountsForLocation(accessToken, location),
      ] as const)

      if (carrierAccount === undefined) {
        return undefined
      }

      const profile = await getCaasProfile(
        accessToken,
        carrierAccount.carrierKey
      )

      const documentTypeKeys = getDocumentTypeKeys(profile)

      return {
        logisticsLocation: location,
        carrierAccount,
        configSpecs: profile.accountConfigSpec,
        existingCarrierAccountNames: (carrierAccounts.filter(
          ca => ca !== undefined
        ) as carrierAccountsClient.models.CarrierAccountWithLink[])
          .map(ca => ca.name)
          .filter(name => name !== carrierAccount.name),
        documentTypeKeys,
        carrierServices: profile.carrierServices.map(
          cs =>
            ({
              label: cs.name,
              value: cs.key,
            } as Option)
        ),
      }
    }
  )
}

function editUploads(
  getAccessToken: () => Promise<string>,
  user: User | undefined,
  location: Location
) {
  return LoaderContainer(UploadsEditor, async (props: any) => {
    const accessToken = await getAccessToken()
    const data = await getLocationConfiguration(
      accessToken,
      user,
      props.match.params.carrierAccountId
    )
    if (data === undefined) {
      return undefined
    }
    const profile = await getCaasProfile(
      accessToken,
      data.carrierAccount.carrierKey
    )
    const documentTypeKeys = getDocumentTypeKeys(data.profile)

    return {
      logisticsLocation: location,
      carrierAccount: data.carrierAccount,
      defaultDocumentUploads: profile.defaultDocumentUploads,
      documentTypeKeys,
      sequenceDefinitionPairs: data.sequenceDefinitionPairs,
    }
  })
}

function editSequences(
  getAccessToken: () => Promise<string>,
  user: User | undefined,
  location: Location
) {
  return LoaderContainer(EditSequencesPage, async (props: any) => {
    const accessToken = await getAccessToken()
    const data = await getLocationConfiguration(
      accessToken,
      user,
      props.match.params.carrierAccountId
    )
    if (data === undefined) {
      return undefined
    }

    return {
      logisticsLocation: location,
      carrierAccount: data.carrierAccount,
      sequenceDefinitionPairs: data.sequenceDefinitionPairs,
    }
  })
}

async function getLocationConfiguration(
  accessToken: string,
  user: User | undefined,
  carrierAccountId: string
) {
  const carrierAccount = await getCarrierAccount(accessToken, carrierAccountId)

  if (!carrierAccount) {
    return undefined
  }

  const profile = await getCaasProfile(accessToken, carrierAccount.carrierKey)

  const sequenceDefinitions = await Promise.all(
    Object.keys(carrierAccount.sequences!).map(async key => ({
      key,
      sequenceDefinition: {
        ...(await getSequenceDirect(
          accessToken,
          user,
          carrierAccount.sequences![key]._links!.self.href
        )),
        _metadata: {
          sequenceKey: key,
          isShared: (profile.sharedSequences || {})[key] !== undefined,
        },
      } as SequenceDefinition,
    }))
  )

  const sequenceDefinitionPairs = sequenceDefinitions.map(sequenceData => ({
    sequence: profile.sequences!.find(s => s.key === sequenceData.key)!,
    sequenceDefinition: sequenceData.sequenceDefinition,
  }))

  return {
    carrierAccount,
    sequenceDefinitionPairs,
    profile,
  }
}
