import { generateLink } from './cimba-proxy'

export async function generateEmbedUrl(
  accessToken: string,
  configurationId: string,
  locationId: string
) {
  const result = await generateLink(accessToken, configurationId, [
    { filter: 'Logistic Location ID', filterValue: locationId },
  ])

  return result.signedEmbedUrl
}
