import * as React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { useTranslation } from 'react-i18next'
import NetworkTools from '../NetworkTools'
import styles from '../ViewNetworkPage.module.css'
import { useNetwork } from '../NetworkContext'
import AddLocationToNetworkModal from './AddLocationToNetworkModal'
import RemoveLocationFromNetworkModal from './RemoveLocationFromNetworkModal'
import LocationsTable from './LocationsTable'

export default function NetworkDetails() {
  const { t } = useTranslation()
  const { network, locations } = useNetwork()

  new Map<string, number>()
  const [
    showAddLocationToNetworkModal,
    setAddLocationToNetworkModal,
  ] = React.useState(false)
  const [
    showRemoveLocationFromNetworkModal,
    setRemoveLocationFromNetworkModal,
  ] = React.useState(false)
  const [
    locationToRemoveFromNetwork,
    setLocationToRemoveFromNetwork,
  ] = React.useState('')

  const openAddLocationToNetworkModal = () => {
    setAddLocationToNetworkModal(true)
  }

  const closeAddLocationToNetworkModal = () => {
    setAddLocationToNetworkModal(false)
  }

  const openRemoveLocationFromNetworkModal = (llId: string) => {
    setLocationToRemoveFromNetwork(llId)
    setRemoveLocationFromNetworkModal(true)
  }

  const closeRemoveLocationFromNetworkModal = () => {
    setLocationToRemoveFromNetwork('')
    setRemoveLocationFromNetworkModal(false)
  }

  const addLocationToNetworkModal = showAddLocationToNetworkModal ? (
    <AddLocationToNetworkModal onClose={closeAddLocationToNetworkModal} />
  ) : null

  const removeLocationFromNetworkModal = showRemoveLocationFromNetworkModal ? (
    <RemoveLocationFromNetworkModal
      onClose={closeRemoveLocationFromNetworkModal}
      logisticsLocationId={locationToRemoveFromNetwork}
    />
  ) : null

  return (
    <>
      <BreadcrumbsItem to="/">{t('common.logisticsLocations')}</BreadcrumbsItem>
      <BreadcrumbsItem to="#">{network.apiNetwork.name}</BreadcrumbsItem>
      <div className="row">
        <div className="col-xs-9">
          <div>
            <h4>{network.apiNetwork.name}</h4>
          </div>
        </div>
        <div className="col-xs-3" />
      </div>
      <div className={`row ${styles.marginTop}`}>
        <div className="col-xs-12 col-md-4">
          <NetworkTools />
        </div>

        <div className="col-xs-12  col-md-8">
          <div className={`card card-minimal ${styles.locationsCard}`}>
            <div className="card-header">
              <h5 className="card-title" style={{ display: 'inline-block' }}>
                {t('common.network')}
              </h5>
              <button
                className="btn-link pull-right"
                onClick={openAddLocationToNetworkModal}
              >
                <i className="fa fa-fw fa-plus" />
                {t('locations.addLocation')}
              </button>
            </div>
            <div className="card-block">
              <LocationsTable
                locations={locations}
                openRemoveLocationFromNetworkModal={
                  openRemoveLocationFromNetworkModal
                }
              />
            </div>
          </div>
        </div>
      </div>
      {addLocationToNetworkModal}
      {removeLocationFromNetworkModal}
    </>
  )
}
