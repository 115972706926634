import { useAuth0 } from '@auth0/auth0-react'
import { Select } from '@cimpress/react-components'
import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import Preloader from '../../common/components/Preloader'
import { SnackbarController } from '../../common/components/SnackbarController'
import { logError } from '../../common/logger'
import * as models from '../../common/models'
import {
  addLocationToNetwork,
  createLocation,
  getAllNetworks,
} from '../../common/proxy/locations-store'
import LocationEditor from '../LocationEditor'
import { getAccounts } from '../../common/proxy/account-store'
import ValidationErrorMessage from '../../common/components/ValidationErrorMessage'

interface Props extends RouteComponentProps<{}> {
  children?: React.ReactNode
}

export default function AddLocationPageContainer(props: Props) {
  const { getAccessTokenSilently, user } = useAuth0()
  const { t } = useTranslation()

  const [saving, setSaving] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [networks, setNetworks] = React.useState<models.Network[]>([])
  const [selectedNetwork, setSelectedNetwork] = React.useState<string>()
  const [serverError, setServerError] = React.useState<models.ServerError>()
  const [showWarning, setShowWarning] = React.useState<boolean>(false)
  const [availableAccounts, setAvailableAccounts] = React.useState<
    models.Account[]
  >([])

  const [selectedAccount, setSelectedAccount] = React.useState<string>()

  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const accessToken = await getAccessTokenSilently()
      const [loadedNetworks, allAccounts] = await Promise.all([
        getAllNetworks(accessToken, user?.sub ?? ''),
        getAccounts(accessToken, user),
      ])
      setLoading(false)
      setNetworks(loadedNetworks.filter(network => network.editable))
      setAvailableAccounts(allAccounts)
    }

    fetchData()
  }, [getAccessTokenSilently, user])

  if (loading) {
    return <Preloader />
  }

  const onSave = async (location: models.Location) => {
    if (!selectedNetwork && !selectedAccount) {
      setShowWarning(true)

      return
    }

    setShowWarning(false)
    setSaving(true)
    try {
      const accessToken = await getAccessTokenSilently()
      const id = await createLocation(accessToken, {
        ...location,
        accountId: selectedAccount,
      })
      if (selectedNetwork) {
        await addLocationToNetwork(accessToken, id, selectedNetwork)
      }
      SnackbarController.show(
        t('locations.locationEditor.logisticsLocationAdded'),
        'success'
      )
      props.history.push('/location/' + id)
    } catch (error) {
      logError(user, 'Error when saving location', error)
      setServerError(error.response.data)
      setSaving(false)
    }
  }

  const onCancel = () => {
    if (props.history.length === 1) {
      props.history.replace('')
    } else {
      props.history.goBack()
    }
  }

  const onSelectNetwork = (data: models.Option) => {
    setSelectedNetwork(data?.value as string)
    if (data?.value) {
      setSelectedAccount(undefined)
    }
    setShowWarning(!data?.value && !selectedAccount)
  }

  const onSelectAccount = (data: models.Option) => {
    setSelectedAccount(data?.value as string)
    setShowWarning(!selectedNetwork && !data?.value)
  }

  const onSelectFulfillmentLocation = (fulfillerAccountId?: string) => {
    if (!selectedNetwork && fulfillerAccountId) {
      setSelectedAccount(fulfillerAccountId)
      setShowWarning(!selectedNetwork && !fulfillerAccountId)
    }
  }

  const getAccountNameFromNetwork = (networkId: string) => {
    const selectedAccountId = networks.find(f => f.apiNetwork.id === networkId)
      ?.apiNetwork.accountId

    return availableAccounts.find(a => a.id === selectedAccountId)?.name
  }

  const networkOptions = networks
    .filter(network => network.editable)
    .map(network => ({
      label: network.apiNetwork.name,
      value: network.apiNetwork.id,
    }))

  const accountOptions = availableAccounts.map(account => ({
    value: account.id,
    label: account.name,
  }))

  const selectNetwork =
    networkOptions.length > 0 ? (
      <Select
        label={t(
          'locations.selectLocationPage.addLocationToNetworkModal.selectANetwork'
        )}
        isSearchable={true}
        isClearable={true}
        name={'selectNetwork'}
        value={networkOptions.filter(o => o.value === selectedNetwork)}
        options={networkOptions}
        onChange={onSelectNetwork}
        menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
      />
    ) : null

  const selectAccount = (
    <>
      <Select
        label={
          selectedNetwork && !selectedAccount
            ? `Inherit account from network (${getAccountNameFromNetwork(
                selectedNetwork
              )})`
            : 'Select account'
        }
        isSearchable={true}
        isClearable={true}
        name={'selectAccount'}
        value={accountOptions.filter(o => o.value === selectedAccount)}
        options={accountOptions}
        onChange={onSelectAccount}
        required={!selectedNetwork}
        menuPortalTarget={document.getElementById(models.reactSelectPortalId)}
        loadingMessage={() => 'loading'}
      />
    </>
  )

  const warning = showWarning && (
    <ValidationErrorMessage message={'Network or account is required'} />
  )

  return (
    <main className="App-content container">
      <LocationEditor
        saving={saving}
        onSave={onSave}
        serverError={serverError}
        onCancel={onCancel}
        onSelectFulfillmentLocation={onSelectFulfillmentLocation}
      >
        {selectNetwork}
        {selectAccount}
        {warning}
      </LocationEditor>
    </main>
  )
}
