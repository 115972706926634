import * as coam from '@cimpress-technology/coam-sapidus'
import { locations } from '@cimpress-technology/logistics-configuration-client'
import * as uuid from 'uuid'

interface Address {
  name: string
  street: string
  street2?: string
  city: string
  postalCode: string
  country: string
}

export interface CreateReturnAddress {
  address: Address

  appliesToCarrier?: string
  appliesToWebsite?: string
}

export interface ReturnAddress extends CreateReturnAddress {
  id: string
}

let returnAddresses: ReturnAddress[] = [
  {
    id: '1',
    address: {
      name: 'DHL Parcel',
      street: 'Lingenstraat 3 A',
      city: 'Zwolle',
      postalCode: '8028 PM',
      country: 'NL',
    },

    appliesToCarrier: 'dhl-api',
    appliesToWebsite: 'drukwerkdeal.nl',
  },
  {
    id: '2',
    address: {
      name: 'PostNL',
      street: 'Lingenstraat 3 A',
      city: 'Zwolle',
      postalCode: '8028 PM',
      country: 'NL',
    },
    appliesToCarrier: 'postnl',
    appliesToWebsite: 'drukwerkdeal.nl',
  },
]

export const getReturnAddresses = (
  networkId: string
): Promise<ReturnAddress[]> => {
  return Promise.resolve(returnAddresses)
}

export const deleteReturnAddress = (id: string): Promise<void> => {
  returnAddresses = returnAddresses.filter(ra => ra.id !== id)

  return Promise.resolve()
}

export const createReturnAddress = (
  returnAddress: CreateReturnAddress
): Promise<void> => {
  const id = (returnAddresses.length + 1).toString()
  returnAddresses = [...returnAddresses, { ...returnAddress, id }]

  return Promise.resolve()
}

export const updateReturnAddress = (
  returnAddress: ReturnAddress
): Promise<void> => {
  const index = returnAddresses.findIndex(ra => ra.id === returnAddress.id)
  returnAddresses[index] = returnAddress

  return Promise.resolve()
}

export const getCarriersByNetwork = (networkId: string): Promise<string[]> => {
  const carriers = returnAddresses.map(ra => ra.appliesToCarrier)

  return Promise.resolve(carriers as string[])
}

export async function addUser(
  accessToken: string,
  networkId: string,
  email: string,
  role: locations.models.LogisticsNetworkRoles
) {
  await locations.addNetworkUser(accessToken, uuid.v4(), networkId, email, role)
}

export async function removeUser(
  accessToken: string,
  networkId: string,
  email: string
) {
  await locations.removeNetworkUser(accessToken, uuid.v4(), networkId, email)
}

export function checkPermission(
  accessToken: string,
  sub: string,
  networkId: string,
  permission: coam.models.LogisticsNetworkPermissions
) {
  return coam.auth.isAllowed(
    networkId,
    coam.models.ResourceTypes.LogisticsNetwork,
    sub,
    permission,
    `Bearer ${accessToken}`,
    uuid.v4()
  )
}
