import { Auth0Provider } from '@auth0/auth0-react'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './common/components/App'
import * as registerServiceWorker from './registerServiceWorker'
import { CLIENT_ID } from './common/auth'

import './i18n'

ReactDOM.render(
  <Router>
    <Auth0Provider
      clientId={CLIENT_ID}
      domain="cimpress.auth0.com"
      audience="https://api.cimpress.io/"
      useRefreshTokens={false}
      scope="openid profile email user_id"
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </Router>,
  document.getElementById('root') as HTMLElement
)
registerServiceWorker.unregister()
