import { User } from '@auth0/auth0-react'
import { carrierAccounts } from '@cimpress-technology/logistics-configuration-client'
import axios from 'axios'
import * as jsonPatch from 'fast-json-patch'
import { config } from '../config/config'
import { logError } from '../logger'

const BACKEND_URL = config.backend

export interface IncompleteCarrierAccount {
  locationId?: string
  id?: string
  etag?: string
  createCarrierAccount: carrierAccounts.models.CreateCarrierAccount
  sequences?: any
  createPickupCalendar: any
}

export const createIncompleteCarrierAccount = async (
  accessToken: string,
  user: User | undefined,
  locationId: string,
  incompleteCarrierAccount: IncompleteCarrierAccount
): Promise<string> => {
  try {
    const response = (
      await axios.post(
        `${BACKEND_URL}/v0/locations/${locationId}/incompleteCarrierAccounts`,
        incompleteCarrierAccount,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    ).data

    return response
  } catch (e) {
    logError(user, 'Error when creating incomplete carrier account', e)

    throw e
  }
}

export const updateIncompleteCarrierAccount = async (
  accessToken: string,
  locationId: string,
  incompleteCarrierAccount: string,
  etag: string,
  patch: jsonPatch.Operation[]
): Promise<string> => {
  const response = await axios.patch(
    `${BACKEND_URL}/v0/locations/${locationId}/incompleteCarrierAccounts/${incompleteCarrierAccount}`,
    patch,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'if-match': etag,
      },
    }
  )

  return response.headers.etag
}

export const getIncompleteCarrierAccounts = async (
  accessToken: string,
  user: User | undefined,
  locationId: string
): Promise<IncompleteCarrierAccount[]> => {
  try {
    return (
      await axios.get(
        `${BACKEND_URL}/v0/locations/${locationId}/incompleteCarrierAccounts`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    ).data
  } catch (e) {
    logError(user, 'Error when getting carrier account', e)
    throw e
  }
}

export const getIncompleteCarrierAccount = async (
  accessToken: string,
  user: User | undefined,
  locationId: string,
  incompleteCarrierAccountId: string
): Promise<IncompleteCarrierAccount> => {
  try {
    return (
      await axios.get(
        `${BACKEND_URL}/v0/locations/${locationId}/incompleteCarrierAccounts/${incompleteCarrierAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    ).data
  } catch (e) {
    logError(user, 'Error when getting carrier account', e)
    throw e
  }
}

export const deleteIncompleteCarrierAccount = async (
  accessToken: string,
  locationId: string,
  incompleteCarrierAccountId: string
): Promise<string> => {
  const response = await axios.delete(
    `${BACKEND_URL}/v0/locations/${locationId}/incompleteCarrierAccounts/${incompleteCarrierAccountId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  return response.headers.etag
}
